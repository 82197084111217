import React, { useEffect } from "react";
import AOS from "aos";
import { FaGreaterThan } from "react-icons/fa";
import { Link } from "react-router-dom";
import jl1 from "../images/jl1.jpg"
import jl2 from "../images/jl2.jpg"
import jl3 from "../images/jl3.jpg"
import jl4 from "../images/jl4.jpg"
import jl5 from "../images/jl5.jpg"
import jl6 from "../images/jl6.jpg"

const Menu = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  });
  return (
    <div className="mnb">
      <div className="menu1-main" data-aos="fade-up">
        <span className="menu1-span">Case Studies</span>
        {/* <span className='menu1-span-1'>Fulcrum Care helps nursing and care homes in England to improve. Take a look at some of the care homes we have helped.</span> */}
      </div>
      <div className="menu-carss" data-aos="fade-up">


          <Link to="/Cases1" className="menu-read-link menu-cars-1">
        
          <img
            className="mennu-cars-img1"
            src={jl1}
            alt=""
          />
          <div className="mennu-cimage">
            <h1 style={{ color: "white" }}>1</h1>
          </div>
          <span className="menu-care">
            Enhancing Staff Competency and Regulatory Compliance at a
            Residential Care Facility
          </span>
          <span className="menu-care1">
            {" "}
            A medium-sized residential care facility, catering to elderly
            residents with diverse care....
          </span>
            {" "}
            <span className="menu-read">
              Read More{" "}
              <FaGreaterThan
                style={{
                  color: "#7469B6",
                  fontSize: "10px",
                  marginTop: 2,
                  marginLeft: 4,
                }}
              />{" "}
            </span>
        
          </Link>

          <Link to="/Case2" className="menu-read-link menu-cars-1">
    
          <img
            className="mennu-cars-img1"
            src={jl2}
            alt=""
          />
          <div className="mennu-cimage">
            <h1 style={{ color: "white" }}>2</h1>
          </div>
          <span className="menu-care">
            Crisis Management and Regulatory Compliance Enhancement at a Nursing
            Home
          </span>
          <span className="menu-care1">
            A medium-sized nursing home faced a critical situation when it
            received an "Inadequate"....
          </span>
        
            {" "}
            <span className="menu-read">
              Read More{" "}
              <FaGreaterThan
                style={{
                  color: "#7469B6",
                  fontSize: "10px",
                  marginTop: 2,
                  marginLeft: 4,
                }}
              />{" "}
            </span>
        
          </Link>

          <Link to="/Case3" className="menu-read-link menu-cars-1">
       
          <img
            className="mennu-cars-img1"
            src={jl3}
            alt=""
          />
          <div className="mennu-cimage">
            <h1 style={{ color: "white" }}>3</h1>
          </div>
          <span className="menu-care">
            Transformation of a Diverse Care Home Portfolio Through Strategic
            Management and Operational Excellence
          </span>
          <span className="menu-care1">
            A large care home provider with a diverse portfolio of facilities
            ....
          </span>
            {" "}
            <span className="menu-read">
              Read More{" "}
              <FaGreaterThan
                style={{
                  color: "#7469B6",
                  fontSize: "10px",
                  marginTop: 2,
                  marginLeft: 4,
                }}
              />{" "}
            </span>
          </Link>
       
          <Link to="/Case4" className="menu-read-link menu-cars-1">
     
          <img
            className="mennu-cars-img1"
            src={jl4}
            alt=""
          />
          <div className="mennu-cimage">
            <h1 style={{ color: "white" }}>4</h1>
          </div>
          <span className="menu-care">
            Optimizing Staffing Efficiency and Resident Care Quality
          </span>
          <span className="menu-care1">
            A medium-sized care home facility faced persistent challenges
            related to staffing ....
          </span>
            {" "}
            <span className="menu-read">
              Read More{" "}
              <FaGreaterThan
                style={{
                  color: "#7469B6",
                  fontSize: "10px",
                  marginTop: 2,
                  marginLeft: 4,
                }}
              />{" "}
            </span>
          </Link>
      
       
          <Link to="/Case5" className="menu-read-link menu-cars-1">
          <img
            className="mennu-cars-img1"
            src={jl5}
            alt=""
          />
          <div className="mennu-cimage">
            <h1 style={{ color: "white" }}>5</h1>
          </div>
          <span className="menu-care">
            {" "}
            Streamlining Documentation Processes for Regulatory Compliance
          </span>
          <span className="menu-care1">
            A large care home organization struggled with inefficient
            documentation processes,....
          </span>
            {" "}
            <span className="menu-read">
              Read More{" "}
              <FaGreaterThan
                style={{
                  color: "#7469B6",
                  fontSize: "10px",
                  marginTop: 2,
                  marginLeft: 4,
                }}
              />{" "}
            </span>
          </Link>
      
          <Link to="/Case6" className="menu-read-link menu-cars-1">
      
          <img
            className="mennu-cars-img1"
            src={jl6}
            alt=""
          />
          <div className="mennu-cimage">
            <h1 style={{ color: "white" }}>6</h1>
          </div>
          <span className="menu-care">
            {" "}
            Improving Care Quality Through Staff Training and Development
          </span>
          <span className="menu-care1">
            A medium-sized care home faced challenges in maintaining high
            standards of care....
          </span>
            {" "}
            <span className="menu-read">
              Read More{" "}
              <FaGreaterThan
                style={{
                  color: "#7469B6",
                  fontSize: "10px",
                  marginTop: 2,
                  marginLeft: 4,
                }}
              />{" "}
            </span>
          </Link>
   
      </div>
    </div>
  );
};

export default Menu;
