import React, { useEffect } from 'react'
import './Mentoring.css'
import '../../AboutPic/AboutPic.css'
import AOS from 'aos'

const Mentoring = () => {
  
    useEffect(()=>{
     AOS.init({duration:1000})
   })
  return (
    <div className='Consultancy'>
    <div className="Mentoring-pic">
  {/* <img src={apic} alt="" srcset="" /> */}
  <div className="about-opacity"></div>
  <div className="about-texts">
<h1>Mentoring & Coaching</h1>
<p> 
Investing in your staff's development is essential for long-term success. Our mentoring and coaching programs provide individualized guidance to help managers and staff develop their skills and progress professionally.
</p>

</div>
</div>

<div className="Consultancy-data">
  <h1 data-aos='fade-up'>Mentoring & Coaching: </h1>

  <p data-aos='fade-up'>From leadership training to communication strategies, we tailor our support to meet your specific needs.
  From leadership training to communication strategies, we tailor our support to your needs. Advance Care Solutions' mentoring and coaching services offer personalized support and guidance to care home managers and staff, fostering professional development and enhancing the quality of care delivery.




</p>


<h4 data-aos='fade-up'>Our mentoring and coaching services include:
</h4>


<h4 data-aos='fade-up'>1.	Leadership Development: </h4>

<p data-aos='fade-up'>
We work closely with care home managers to enhance their leadership, communication, and decision-making skills. Through one-on-one coaching sessions and personalized development plans, we empower managers to effectively lead their teams and drive positive change within the care home.
</p>


<h4 data-aos='fade-up'>2.	Skill Enhancement: </h4>
<p data-aos='fade-up'> 
Our mentoring and coaching programs focus on developing specific skills and competencies relevant to the care home environment. This may include training on clinical best practices, regulatory compliance, effective communication, conflict resolution, and resident-centered care approaches.
</p>
<h4 data-aos='fade-up'>3.	Performance Improvement:</h4>
<p data-aos='fade-up'>
We provide ongoing support and feedback to care home managers and staff to help them identify areas for improvement and implement strategies for enhancing performance. This may involve setting performance goals, monitoring progress, and providing targeted interventions as needed.
</p>
<h4 data-aos='fade-up'>4.	Staff Engagement: </h4>
<p data-aos='fade-up'>
We offer coaching and mentoring support to frontline staff to enhance their job satisfaction, motivation, and engagement. By providing opportunities for skill development and career advancement, we help foster a positive work environment and improve retention rates.
</p>

<h4 data-aos='fade-up'>5.	Continuous Learning:</h4>
<p data-aos='fade-up'>
Our mentoring and coaching programs promote a culture of continuous learning and professional growth within the care home. We encourage managers and staff to pursue further education, attend training workshops, and stay updated on industry best practices and trends.
</p>


<h4 data-aos='fade-up'>6.	Personalized Support: </h4>
<p data-aos='fade-up'>
We recognize that each care home is unique, and our mentoring and coaching services are tailored to meet individual managers' and staff members' specific needs and goals.
</p>
<p data-aos='fade-up'>
Our experienced mentors and coaches provide personalized guidance and support to ensure maximum impact and effectiveness. By partnering with Advance Care Solutions for mentoring and coaching services, care homes can cultivate a skilled and empowered workforce, drive performance improvement, and ultimately enhance the quality of care provided to residents.
</p>
</div>


</div>
  )
}

export default Mentoring