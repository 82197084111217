import React from 'react'
import Nav from './Nav'
import Main from './Main'
import Menu from './Menu'
import About from './About'
import Connect from './Connect'
import Middle from './Middle'
import Footer from './Footer'
import Navbar1 from '../MainFolder/Navbar1'
import Main1 from '../MainFolder/Main1'
import Dna from '../MainFolder/Dna'
import { Card } from '../MainFolder/Card'

const Home = () => {
  return (
    <div>

      {/* <Navbar1/> */}
      <Dna/>
      <Main1/>
      <Card/>
        
        {/* <Main/>
        <Middle/> */}
        {/* <Menu/> */}
        {/* <About/>
        <Connect/> */}
        {/* <Footer/> */}
    </div>
  )
}

export default Home