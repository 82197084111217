import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './About/About';
import Service from './Services/Service';
import Carecqc from './MainFolder/Carecqc';
import Case from './MainFolder/Case';
import League from './MainFolder/League';
import Casestudies from './MainFolder/Casestudies';
import Contacts from './MainFolder/Contacts';
import Testimonial from './AllPages/Testimonial/Testimonial';
import Partnership from './AllPages/Partnership/Partnership';
import OurTeam from './AllPages/OurTeam/OurTeam';
import JoinOurteam from './AllPages/JoinOurteam/JoinOurteam';
import Consultancy from './AllPages/Consultancy/Consultancy';
import CQCChallenges from './AllPages/CQCChallenges/CQCChallenges';
import CrisisManagement from './AllPages/CrisisManagement/CrisisManagement ';
import DementiaSupport from './AllPages/DementiaSupport/DementiaSupport';
import Governance from './AllPages/Governance/Governance';
import InHomeManage from './AllPages/InHomeManage/InHomeManage';
import Mentoring from './AllPages/Mentoring/Mentoring';
import PersonCentered from './AllPages/Person-Centered/PersonCentered';
import Insight from './MainFolder/Insight';
import Crisis2 from './AllPages/CrisisMain/Crisis2';
import Cases1 from './Casestadies/Case1/Cases1';
import Case2 from './Casestadies/Case2/Case2';
import Case3 from './Casestadies/Case3/Case3';
import Case4 from './Casestadies/Case4/Case4';
import Case5 from './Casestadies/Case5/Case5';
import Case6 from './Casestadies/Case6/Case6';
import './App.css';
import OurHome from './AllPages/OurHome/OurHome';
import OurHome2 from './AllPages/OurHome/OurHome2/OurHome2';

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation();

  const isLastSixCases = location.pathname.includes('Cases1') || location.pathname.includes('Case2') || location.pathname.includes('Case3') || location.pathname.includes('Case4') || location.pathname.includes('Case5') || location.pathname.includes('Case6');

  return (
    <>
      <Nav isLastSixCases={isLastSixCases} />
      <Routes>
        <Route index element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/service' element={<Service />} />
        <Route path='/care' element={<Carecqc />} />
        <Route path='/case' element={<Case />} />
        <Route path='/league' element={<League />} />
        <Route path='/casestudy' element={<Casestudies />} />
        <Route path='/contacts' element={<Contacts />} />
        <Route path='/Testimonial' element={<Testimonial />} />
        <Route path='/Partnership' element={<Partnership />} />
        <Route path='/Ourteam' element={<OurTeam />} />
        <Route path='/Joinourteam' element={<JoinOurteam />} />
        <Route path='/Consultancy' element={<Consultancy />} />
        <Route path='/CQCChallenges' element={<CQCChallenges />} />
        <Route path='/CrisisManagement' element={<CrisisManagement />} />
        <Route path='/DementiaSupport' element={<DementiaSupport />} />
        <Route path='/Governance' element={<Governance />} />
        <Route path='/Inhome' element={<InHomeManage />} />
        <Route path='/Mentoring' element={<Mentoring />} />
        <Route path='/PersonCentered' element={<PersonCentered />} />
        <Route path='/insight' element={<Insight />} />
        <Route path='/crisis1' element={<Crisis2 />} />
        <Route path='/Cases1' element={<Cases1 />} />
        <Route path='/Case2' element={<Case2 />} />
        <Route path='/Case3' element={<Case3 />} />
        <Route path='/Case4' element={<Case4 />} />
        <Route path='/Case5' element={<Case5 />} />
        <Route path='/Case6' element={<Case6 />} />

        <Route path='/ourhome' element={<OurHome />} />
        <Route path='/ourhome2' element={<OurHome2 />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
