import React, { useEffect } from "react";
import AOS from "aos";
import { MdControlPoint } from "react-icons/md";
const Caremenu = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  });
  const accordionStyle = {
    borderColor: "rgba(0, 255, 0, 0.125)", // Set your desired border color here
  };
  return (
    <>
      <div className="menu1-main" data-aos="fade-up">
        <span className="menu1-span card-head-shotq" style={{ fontSize: 25 }}>
        CQC Inspections & Audit
        </span>
        <span className="card-shot-span1 care-menu-think">
        Welcome to Advance Care Consultancy, your trusted partner in ensuring excellence in care provision through meticulous CQC Inspections & Audit. In this comprehensive guide, we delve deeper into the intricacies of maintaining high standards in care homes and how our tailored audit services can support you in this endeavor.
        </span>
      </div>
      <div className="care-menu-main-container">
        <div className="card-single3">
          <span>Introduction: </span>
          <div className="care-menu-one1" data-aos="fade-up">
            {/* <span>Need Urgent Help</span> */}
            <span className="care-need-help" data-aos="fade-up">
            At Advance Care Consultancy, we recognize the importance of maintaining an exemplary Care Quality Commission (CQC) rating for care homes. Your CQC rating is a hallmark of the quality of care your establishment delivers, influencing your reputation, resident satisfaction, and overall viability in the industry. Whether your care home currently boasts an outstanding rating or is striving to improve from an inadequate one, our consultancy services are designed to empower you to achieve and sustain excellence.

            </span>
          </div>
        </div>

        <div className="card-single3">
          <span>The Significance of CQC Ratings: </span>
          <div className="care-menu-one1" data-aos="fade-up">
            {/* <span>Need Urgent Help</span> */}
            <span className="care-need-help" data-aos="fade-up">
            Your CQC rating is more than just a badge of honour; it reflects your commitment to providing exceptional care to your residents. A positive rating enhances your reputation and serves as a powerful marketing tool, attracting discerning residents and their families to your facility. Conversely, a subpar rating can harm your business, impacting occupancy rates, staff morale, and financial viability. Therefore, safeguarding and enhancing your CQC rating should be a top priority for any care home operator.

            </span>
          </div>
        </div>

        <div className="card-single3">
          <span>The Role of Mock CQC Inspections:</span>
          <div className="care-menu-one1" data-aos="fade-up">
            {/* <span>Need Urgent Help</span> */}
            <span className="care-need-help" data-aos="fade-up">
              {" "}
              Mock CQC inspections are invaluable tools for healthcare providers to simulate the actual inspection process and evaluate their compliance with CQC standards and regulations. By conducting mock inspections, care homes can identify gaps in their compliance and make necessary adjustments to ensure they meet the required standards before the official inspection. Moreover, mock inspections allow staff members to familiarize themselves with the inspection process and expectations, building confidence and competence.

            </span>
          </div>

          <span>Enhancing Patient Safety and Quality of Care:</span>
          <div className="care-menu-one1" data-aos="fade-up">
            {/* <span>Need Urgent Help</span> */}
            <span className="care-need-help" data-aos="fade-up">
              {" "}
              One of the primary objectives of mock inspections is to identify risks and hazards that could potentially harm patients. By addressing these issues proactively, care homes can significantly enhance the quality of care and create a safer environment for their residents. Our consultants provide detailed recommendations for improving patient safety and quality of care, which may include:

              <div
                class="accordion accordion-flush"
                style={{ color: "black" }}
                id="accordionFlushExample"
              >
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Implementing
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Implementing robust infection control measures to prevent
                      the spread of infections and safeguard resident health.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Enhancing
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Enhancing communication protocols between staff members
                      and residents to foster trust and understanding.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Improving
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Improving documentation processes to ensure accurate
                      record-keeping and facilitate continuity of care.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFour">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Conducting
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Conducting regular audits and reviews to monitor and
                      address any emerging issues promptly, promoting a culture
                      of continuous improvement.
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>

        <div className="card-single3">
          <span>Identifying Strengths and Weaknesses: </span>
          <div className="care-menu-one1" data-aos="fade-up">
            <span className="care-need-help" data-aos="fade-up">
            At Advance Care Solutions, we understand the importance of conducting thorough evaluations to ensure healthcare facilities meet the highest standards of care. Our experienced consultants utilize specialized mock CQC inspection toolkits designed to assess your organization comprehensively. <br /><br />
Our consultants meticulously scrutinize various aspects of your care home operations during the mock inspection process. This includes examining documentation, observing care delivery practices, and conducting interviews with staff members. By employing a detailed approach, we can identify your organization's strengths and weaknesses. <br /><br />
Identifying strengths allows us to recognize areas where your facility excels, whether providing compassionate care, maintaining safety protocols, or fostering a positive work environment.
            </span>

            <div class="accordion" id="accordionExample" style={{width:"99%"}}>
              <div class="accordion-item">
                <h2 class="accordion-header  ">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    style={{color:"black"}}
                  >
                    Quality of Care Plans:
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    We assess the effectiveness and suitability of care plans in
                    meeting the individual needs of residents, ensuring
                    personalized care delivery.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    Human Resources Management:
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Our consultants evaluate HR practices, including
                    recruitment, training, and staff retention strategies, to
                    foster a supportive and competent workforce.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    Medication Management:
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    We examine medication administration procedures to ensure
                    compliance with regulatory standards and promote patient
                    safety.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    Personnel and Staff Files:
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    A thorough review of staff files ensures that all necessary
                    documentation and certifications are up-to-date and
                    compliant, mitigating potential risks.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    Staffing and Rotas:
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    We analyze staffing levels and rota systems to ensure
                    adequate coverage and optimal care delivery, minimizing
                    disruptions in service provision.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="true"
                    aria-controls="collapseSix"
                  >
                    Staff Training
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Assessing staff training programs ensures that employees are
                    equipped with the necessary skills and knowledge to provide
                    high-quality care, promoting continuous
                    professional development.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-single3">
          <span>Building Staff Confidence and Competence: </span>
          <div className="care-menu-one1" data-aos="fade-up">
            {/* <span>Need Urgent Help</span> */}
            <span className="care-need-help" data-aos="fade-up">
         Mock CQC inspections also play a crucial role in building confidence and competence among staff members. By familiarizing themselves with the inspection process and expectations, staff can ensure they are well-prepared for official inspections, thereby contributing to the overall success of the care home. We provide tailored training and support to help staff members understand their roles and responsibilities during CQC inspections, including <br />• Conducting mock interviews and role-playing scenarios to simulate inspection interactions and enhance communication skills. <br /> • Offering guidance on presenting evidence and responding to inspector queries confidently and effectively. <br /> • Providing access to resources and materials to support staff development and continuous learning, empowering them to deliver exceptional care consistently.

            </span>
          </div>
        </div>

        <div className="card-single3">
          <span>Our Audits </span>
          <div className="care-menu-one1-inspection" data-aos="fade-up">
            {/* <span>Need Urgent Help</span> */}
            <span className="care-need-help-font" data-aos="fade-up">
              <MdControlPoint /> Mini Mock Inspection <br /> <br />
              <MdControlPoint /> Care plans audit <br /> <br />
              <MdControlPoint /> Medications audit <br /> <br />
              <MdControlPoint /> HR audit <br /> <br />
              <MdControlPoint /> Personnel and staff file audit <br />
              <br />
              <MdControlPoint /> Staff rota audit <br /> <br />
              <MdControlPoint />
              Staff training audit <br /> <br />
              <MdControlPoint /> CQC Inspection Readiness (full care
              home health check)
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Caremenu;

// import React, { useEffect } from 'react'
// import AOS from 'aos'
// const Caremenu = () => {
//       useEffect(()=>{
//     AOS.init({duration:1000})
//   })
//   return (
//     <>
//      <div className='menu1-main' data-aos='fade-up'>
//                 <span className='menu1-span card-head-shotq' style={{fontSize:25}} >Tell us what you think!</span>
//                 <span className="card-shot-span1 care-menu-think">How have used the insights from our free interactive CQC data dashboards? What other data would help you improve your care home business? We’d love your feedback to help us refine our dashboards to give you the information you need to manage your business effectively.</span>
//               </div>
//     <div className='care-menu-main-container'>

//          <div className='care-menu-one1'  data-aos='fade-up'>
//             <span>Need Urgent Help</span>
//             <span className='care-need-help' data-aos='fade-up'>8606206747</span>
//          </div>

//          <div className='care-menu-one1' data-aos='fade-up'>
//             <span>Contact Us Today</span>
//          </div>
//          <div className='care-menu-one1' data-aos='fade-up'>
//             <span>Back to dashboards</span>
//          </div>
//          <div className='care-menu-one1' data-aos='fade-up'>
//             <span>Care Home Ratings</span>
//          </div>

//     </div>

//     </>
//   )
// }
// export default Caremenu
