import React, { useEffect } from 'react';
import './Main1.css';
import a1 from './img1/b1.png';
import a2 from './img1/b2.png';
import a3 from './img1/b3.png';
import a4 from './img1/b4.png';
import a5 from './img1/b5.png';
import a6 from './img1/b6.png';
import a7 from './img1/a3.png';
import a8 from './img1/a2.png';
import a9 from './img1/a1.png';
import { Link } from 'react-router-dom';

const Dna = () => {
  useEffect(() => {
    const items = document.querySelectorAll('.kill');
    items.forEach((item, index) => {
      const randomDelay = Math.random() * 2; // Generate a random delay between 0 and 2 seconds
      item.style.animationDelay = `${randomDelay}s`; // Set the animation delay for each item
    });
  }, []);

  return (
    <div className='dna-one'>
      <div className='dna-background'>
        <span className='dna-heading'>Welcome To  Advance Care  Solutions Ltd</span>
        <span className='dna-graet'>Where Quality Care Meets Compassionate Support.</span>
      <div className='kil11'>
       
       {/* <Link to='/CrisisManagement'> <div className='kill'>
          <img  src={a1} alt="" />
          <span>Consultancy</span>
        </div>
        </Link> */}



        <div className='kill'>
         <Link className='dna-icon-link-align' style={{textDecoration:"none"}} to='/CrisisManagement'> <img  src={a1} alt="" />
          <span>Consultancy</span>
          </Link>
        </div>
        <div className='kill dna-zoom'>
          <Link className='dna-icon-link-align' style={{textDecoration:"none"}}>
          <img  src={a2} alt="" />
          <span>CQC Dashboard</span>
          </Link>
        </div>
        <div className='kill'>
         <Link className='dna-icon-link-align' style={{textDecoration:"none"}} to='/Mentoring'> <img  src={a3} alt="" />
          <span>Mentoring</span>
          </Link>
        </div>
        <div className='kill'>
       <Link className='dna-icon-link-align' style={{textDecoration:"none"}} to='/Governance'>   <img  src={a4} alt="" />
          <span>Governance</span>
          </Link>
        </div>

        <div className='kill'>
          <Link  className='dna-icon-link-align' style={{textDecoration:"none"}} to='/DementiaSupport'>
          <img  src={a5} alt="" />
          <span>Dementia</span>
          </Link>
        </div>
        <div className='kill'>
         <Link className='dna-icon-link-align' style={{textDecoration:"none"}} to='/care'>
          <img  src={a6} alt="" />
          <span>Audits</span>
          </Link>
        </div>
        
        <div className='kill'>
     <Link className='dna-icon-link-align' style={{textDecoration:"none"}} to='/CQCChallenges'>
          <img  src={a7} alt="" />
          <span>CQC Challenges</span>
        </Link>
        </div>
        <div className='kill'>
         <Link className='dna-icon-link-align' style={{textDecoration:"none"}} to='/crisis1'>
          <img  src={a8} alt="" />
          <span>Crisis Management</span>
          </Link>
        </div>
        <div className='kill'>
          <Link className='dna-icon-link-align'  style={{textDecoration:"none"}} to='/Inhome'>
          <img  src={a9} alt="" />
          <span>Management</span>
          </Link>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Dna;
