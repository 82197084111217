import React, { useEffect } from "react";
import one from "./img1/one.png";
import two from "./img1/c22 (1).png";
import three from "./img1/c23 (1).png";
import { FaGreaterThan } from "react-icons/fa";
import AOS from "aos";
import four from "./img1/c24 (1).png";
import five from "./img1/c25 (1).png";
import six from "./img1/c26 (1).png";
import seven from "./img1/c27 (1).png";
import eight from "./img1/c28 (1).png";
import nine from "./img1/c29 (1).png";
import ten from "./img1/c21 (1).png";
import "./Main1.css";
import { Link } from "react-router-dom";
export const Card = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  });
  return (
    <>
      <div className="menu1-main" data-aos="fade-up">
        <span className="menu1-span card-head-shotq">Our Services</span>
        <span className="menu1-span-1 card-head-shot">
          What We Can Do Together
        </span>
      </div>

      <div className="card-main">

      <Link className="jiji" to='/CrisisManagement'> 
        <div className="card-short1" data-aos="fade-up">
          <img className="card-shot-img1" src={ten} alt="" />
          <span className="card-shot-span">Consultancy & Support</span>
          <span className="card-shot-span1">
          Consultancy and support services play a crucial role in helping care homes ...
          </span>
         <span className="menu-read card-shot-read">
            Read More{" "}
            <FaGreaterThan
              style={{
                color: "#7469B6",
                fontSize: "10px",
                marginTop: 5,
                marginLeft: 4,
              }}
            />{" "}
          </span>
        </div>
            </Link>
            <Link className="jiji" to='/casestudy'>

        <div className="card-short1" data-aos="fade-up">
          <img className="card-shot-img1" src={two} alt="" />
          <span className="card-shot-span">Case Studies</span>
          <span className="card-shot-span1">
          Enhancing Staff Competency and Regulatory Compliance at a...          </span>
           <span className="menu-read card-shot-read">
            Read More{" "}
            <FaGreaterThan
              style={{
                color: "#7469B6",
                fontSize: "10px",
                marginTop: 5,
                marginLeft: 4,
              }}
            />{" "}
          </span>
        </div>
          </Link>
          <Link to='/Mentoring' className="jiji">

        <div className="card-short1" data-aos="fade-up">
          <img className="card-shot-img1" src={three} alt="" />
          <span className="card-shot-span">Mentoring & Coaching</span>
          <span className="card-shot-span1">
          From leadership training to communication strategies, we...         </span>
          <span className="menu-read card-shot-read">
            Read More{" "}
            <FaGreaterThan
              style={{
                color: "#7469B6",
                fontSize: "10px",
                marginTop: 5,
                marginLeft: 4,
              }}
            />{" "}
          </span>
        </div>
          </Link> 
          <Link to='/Governance' className="jiji">  

        <div className="card-short1" data-aos="fade-up">
          <img className="card-shot-img1" src={four} alt="" />
          <span className="card-shot-span">Governance & Compliance</span>
          <span className="card-shot-span1">
          Good governance is crucial for the smooth operation of care homes...          </span>
       <span className="menu-read card-shot-read">
            Read More{" "}
            <FaGreaterThan
              style={{
                color: "#7469B6",
                fontSize: "10px",
                marginTop: 5,
                marginLeft: 4,
              }}
            />{" "}
          </span>
        </div>
          </Link> 
        <Link to='/DementiaSupport' className="jiji">
        <div className="card-short1" data-aos="fade-up">
          <img className="card-shot-img1" src={five} alt="" />
          <span className="card-shot-span">Dementia Support Services</span>
          <span className="card-shot-span1">
          Advance Care Solutions offers Dementia-Behavioural and Psychological 
          </span>
           <span className="menu-read card-shot-read">
            Read More{" "}
            <FaGreaterThan
              style={{
                color: "#7469B6",
                fontSize: "10px",
                marginTop: 5,
                marginLeft: 4,
              }}
            />{" "}
          </span>
        </div>
          </Link> 
       <Link to='/care' className="jiji">  
        <div className="card-short1" data-aos="fade-up">
          <img className="card-shot-img1" src={six} alt="" />
          <span className="card-shot-span">CQC Inspections & Audit</span>
          <span className="card-shot-span1">
          Welcome to Advance Care Consultancy, your trusted partne...          </span>
        <span className="menu-read card-shot-read">
            Read More{" "}
            <FaGreaterThan
              style={{
                color: "#7469B6",
                fontSize: "10px",
                marginTop: 5,
                marginLeft: 4,
              }}
            />{" "}
          </span>
        </div>
          </Link>
          <Link to='/CQCChallenges' className="jiji">
        <div className="card-short1" data-aos="fade-up">
          <img className="card-shot-img1" src={seven} alt="" />
          <span className="card-shot-span">CQC Challenges</span>
          <span className="card-shot-span1">
          CQC challenges encompass various scenarios, including disputes over inspectio...          </span>
         
             <span className="menu-read card-shot-read">
            Read More{" "}
         <FaGreaterThan
              style={{
                color: "#7469B6",
                fontSize: "10px",
                marginTop: 5,
                marginLeft: 4,
              }}
            />{" "}
          </span>
        </div>
          </Link> 
        <Link to='/crisis1' className="jiji">
        <div className="card-short1" data-aos="fade-up">
          <img className="card-shot-img1" src={eight} alt="" />
          <span className="card-shot-span">Care Home Crisis Management</span>
          <span className="card-shot-span1">
          At Advance Care Solutions, we understand that crisis...          </span>
           <span className="menu-read card-shot-read">
            Read More{" "}
            <FaGreaterThan
              style={{
                color: "#7469B6",
                fontSize: "10px",
                marginTop: 5,
                marginLeft: 4,
              }}
            />{" "}
          </span>
        </div>
          </Link> 
         <Link to='/Inhome' className="jiji">
        <div className="card-short1" data-aos="fade-up">
          <img className="card-shot-img1" src={nine} alt="" />
          <span className="card-shot-span">In Home Management</span>
          <span className="card-shot-span1">
          In-home management services provided by Advance Care Solutions...          </span>
         
           <span className="menu-read card-shot-read">
            Read More{" "}
            <FaGreaterThan
              style={{
                color: "#7469B6",
                fontSize: "10px",
                marginTop: 5,
                marginLeft: 4,
              }}
            />{" "}
          </span>
        </div>
          </Link>
      </div>
    </>
  );
};
