import React, { useEffect } from "react";
import "./Crisis2.css";
import "../../AboutPic/AboutPic.css";
import AOS from 'aos'


const Crisis2 = () => {
 
    useEffect (()=>{
     AOS.init({duration:1000})
   })
  return (
    <div className="Consultancy">
      <div className="CrisisManagement-pic">
        <div className="about-opacity"></div>
        <div className="about-texts">
          <h1>Care Home Crisis Management</h1>
          <p></p>
        </div>
      </div>

      <div className="Consultancy-data">
        <h1 data-aos='fade-up'>Care Home Crisis Management </h1>

        <p data-aos='fade-up'>
        At Advance Care Solutions, we understand that crisis situations can arise unexpectedly in care home settings, posing significant challenges to the well-being of residents and the facility's reputation. Our Crisis Management Solutions are designed to provide swift and effective support to address critical issues and ensure continuity of care.

        </p>

        <h4 data-aos='fade-up'>Key Features:</h4>

        <h4 data-aos='fade-up'>1. Immediate Response: </h4>

        <p data-aos='fade-up'>
        Our team of experienced consultants is available 24/7 to respond to crises promptly. Whether it's a staffing shortage, a regulatory compliance issue, or a resident safety concern, we are here to provide guidance and support when you need it most.

        </p>

        <h4 data-aos='fade-up'>2. Comprehensive Assessment: </h4>
        <p data-aos='fade-up'>
        We thoroughly assess the crisis situation to understand its root causes and potential risks. Our consultants work closely with care home management to gather information, assess the severity of the situation, and develop an action plan tailored to the facility's specific needs.

        </p>
        <h4 data-aos='fade-up'>3. Strategic Planning: </h4>
        <p data-aos='fade-up'>
        Based on our assessment, we developed a strategic plan to address the crisis and mitigate risks effectively. This may involve implementing temporary measures to ensure resident safety, coordinating with regulatory authorities, and communicating with stakeholders to maintain transparency and trust.

        </p>
        <h4 data-aos='fade-up'>4. Resource Allocation: </h4>
        <p data-aos='fade-up'>
        We assist care homes in optimizing resource allocation during crises, ensuring that staff, equipment, and other resources are deployed efficiently to address immediate needs. Our goal is to minimize disruption to care services and maintain the well-being of residents throughout the crisis.

        </p>

        <h4 data-aos='fade-up'>5. Continuous Support</h4>
        <p data-aos='fade-up'>
        Our support continues once the immediate crisis is resolved. We provide ongoing support and guidance to help care homes implement long-term solutions and prevent similar incidents from occurring in the future. Whether implementing new protocols, providing staff training, or conducting follow-up assessments, we are committed to supporting care homes every step of the way.

        </p>
        <h4 data-aos='fade-up' style={{ color: "#7469B6" }}>Key Features:</h4>

        <h4 data-aos='fade-up'>Peace of Mind:</h4>
        <p data-aos='fade-up'>
        With Advance Care Solutions by your side, you can have peace of mind knowing that experienced professionals are handling crises effectively and efficiently.

        </p>

        <h4 data-aos='fade-up'>Maintained Reputation:</h4>
        <p data-aos='fade-up'>
        Our proactive approach to crisis management helps care homes maintain their reputation and credibility within the community and among regulatory authorities.

        </p>

        <h4 data-aos='fade-up'>Improved Resident Care:</h4>
        <p data-aos='fade-up'>
        By addressing crises promptly and effectively, we ensure that the well-being and safety of residents remain the top priority at all times.

        </p>

        <p data-aos='fade-up'>
        Don't wait until a crisis strikes. Contact Advance Care Solutions today to learn more about our Crisis Management Solutions and how we can help your care home navigate challenging times with confidence and resilience.

        </p>
      </div>
    </div>
  );
};

export default Crisis2;
