import React, { useEffect } from 'react'
import './Main1.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import AOS from 'aos'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'; // Import modules from 'swiper' directly
const Main1 = () => {
    const onAutoplayTimeLeft = (s, time, progress) => {
        // Here you can handle the time left if needed
      };

      useEffect(()=>{
        AOS.init({duration:2000})
      })
  return (

    <>
    <div>
    <Swiper
      spaceBetween={0}
      loop={true}
      // centeredSlides={true}
      autoplay={{
        delay: 6000,
        // disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={false}
      modules={[Autoplay, Pagination, Navigation]}
      // onAutoplayTimeLeft={onAutoplayTimeLeft}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className='swiper-content1-one'>
        <div className='akhilll'>
            <div  className='swiper-content1-one-one'   data-aos='fade-left'>
                <span className='swi-span1'>Assessment of the Service</span>
                <span className='swi-span2'>At Advance Care, we prioritize the thorough assessment of every aspect of care delivery. Our experienced team conducts detailed evaluations, examining policies, practices, and resident feedback to identify strengths and areas for improvement. Through comprehensive assessments, we lay the groundwork for tailored solutions that enhance the quality of care provided by our partner care homes.
</span>
            </div>
        </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='swiper-content1-two'>

      <div className='akhilll'>
            <div  className='swiper-content1-one-one'  data-aos='fade-left'>
                <span className='swi-span1'>Analyzing the Data</span>
                <span className='swi-span2'> Data analysis is at the heart of our approach to continuous improvement at Advance Care. By meticulously analyzing data collected during assessments and daily operations, we uncover valuable insights that inform decision-making. Through advanced analytics, we identify trends, monitor performance metrics, and track progress towards key objectives, ensuring our interventions are evidence-based and impactful.
</span>
            </div>
        </div>
       
        </div>
       
       
       

      </SwiperSlide>
      <SwiperSlide>
      
      <div className='swiper-content1-three'>
      <div className='akhilll'>
            <div  className='swiper-content1-one-one'  data-aos='fade-up'>
                <span className='swi-span1'>Implementing the Practice</span>
                <span className='swi-span2'>At Advance Care, we don't just identify best practices; we ensure they are effectively implemented and integrated into everyday operations. Working closely with care home teams, we develop customized implementation plans tailored to each facility's unique needs and challenges. Through hands-on support, training, and ongoing guidance, we empower care home staff to adopt and sustain best practices, driving continuous improvement in care delivery.
</span>
            </div>
        </div>
      
        </div>
      
      

      </SwiperSlide>
      <SwiperSlide>
      <div className='swiper-content1-four'>
<div className='swiper-content1-four-one'>
            <div  className='swiper-content1-one-one swiper-content1-one-one-side'  data-aos='fade-up'>
                <span className='swi-span1'>Partnership and Monitoring</span>
                <span className='swi-span2'> Partnership and collaboration are central to our approach at Advance Care. We forge strong relationships with care home management, staff, and residents' families, fostering a shared commitment to excellence. Through regular communication, feedback loops, and transparent reporting, we maintain open lines of communication and ensure alignment with our partners' goals. Our proactive monitoring processes enable us to track progress, address concerns, and adapt strategies as needed, ensuring our partners achieve sustained success.
</span>
            </div>
        </div>
        </div >




      </SwiperSlide>
      <SwiperSlide>

      <div className='swiper-content1-five'>
        <div className='akhilll'>
            <div  className='swiper-content1-one-one'  data-aos='fade-left'>
                <span className='swi-span1'>Leading into Success</span>
                <span className='swi-span2'>Effective leadership is critical to the success of any care home, and Advance Care is committed to nurturing and empowering leaders within our partner facilities. Through targeted coaching, mentorship, and leadership development programs, we equip care home managers and staff with the skills and confidence needed to drive positive change. By fostering a culture of accountability, innovation, and continuous learning, we empower our partners to lead their teams to success and deliver exceptional care experiences.</span>
            </div>
        </div>
        </div>

      </SwiperSlide>
 
 
    </Swiper>
    </div>
  </>
  )
}

export default Main1