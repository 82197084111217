import React, { useEffect } from 'react'
import './PersonCentered.css'
import '../../AboutPic/AboutPic.css'
import AOS from 'aos'

const PersonCentered = () => {
 
    useEffect(()=>{
     AOS.init({duration:1000})
   })
  return (
    <div className='Consultancy'>
    <div className="PersonCentered-pic">
  {/* <img src={apic} alt="" srcset="" /> */}
  <div className="about-opacity"></div>
  <div className="about-texts">
<h1>Advance QMS</h1>
<p> 
Stay ahead of regulatory requirements with Advance QMS, our quality management system designed to streamline audits, reporting, and self-assessment. With a suite of audits aligned with CQC standards, Advance QMS provides visibility and insight into the management and day-to-day running of your care home, helping you raise standards and achieve improved levels of quality.
</p>


</div>
</div>

<div className="Consultancy-data">
  <h1  data-aos='fade-up'>Advance QMS</h1>

  <p  data-aos='fade-up'>
  Advance QMS (Quality Management System) is an innovative tool designed by Advance Care Solutions to empower care homes to manage their operations effectively and ensure compliance with regulatory standards.

</p>


<h4  data-aos='fade-up'>Key features of Advance QMS include:


</h4>


<h4  data-aos='fade-up'>1.	Comprehensive Audits: </h4>

<p  data-aos='fade-up'>
The system offers a suite of audits aligned with the Care Quality Commission's (CQC) Key Lines of Enquiry (KLOEs), enabling care homes to assess their performance across various domains, including safety, effectiveness, and responsiveness.
</p>


<h4  data-aos='fade-up'>2.	Real-time Reporting: </h4>
<p  data-aos='fade-up'> 
Advance QMS allows care homes to collect and analyze data in real time, providing instant insights into their performance and areas for improvement. This enables timely decision-making and action planning to address identified issues.
</p>
<h4  data-aos='fade-up'>3.	Actionable Insights: </h4>
<p  data-aos='fade-up'>
The system generates detailed reports and analytics, highlighting trends, patterns, and areas of non-compliance. Care home managers can use this information to implement targeted interventions and drive continuous quality improvement.
</p>
<h4  data-aos='fade-up'>4.	Task Management: </h4>
<p  data-aos='fade-up'>
Advance QMS facilitates the assignment and tracking of corrective actions and improvement initiatives, ensuring accountability and follow-up on identified deficiencies.
</p>

<h4  data-aos='fade-up'>5.	Compliance Monitoring:</h4>
<p  data-aos='fade-up'>
The system helps care homes monitor compliance with regulatory requirements, track documentation, and evidence their adherence to standards during regulatory inspections.
 </p>


<h4  data-aos='fade-up'>6.	User-friendly Interface: </h4>
<p  data-aos='fade-up'>
Advance QMS features an intuitive and user-friendly interface, making it easy for care home staff to navigate and utilize the system effectively.
</p>
<h4  data-aos='fade-up'>7.	Customization Options:</h4>
<p  data-aos='fade-up'>
Care homes can customize the system to align with their specific requirements, including the ability to create bespoke audits, templates, and reporting formats.
</p>

<h4  data-aos='fade-up'>8.	Continuous Support</h4>
<p  data-aos='fade-up'>
Advance Care Solutions provides ongoing support and training to care homes using Advance QMS, ensuring they maximize the system's benefits and stay up-to-date with regulatory changes and best practices.
</p>
<p  data-aos='fade-up'>
By leveraging Advance QMS, care homes can streamline their quality management processes, enhance operational efficiency, and demonstrate their commitment to delivering high-quality care to residents.
</p>

</div>
</div>
  )
}

export default PersonCentered