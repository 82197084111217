import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollMagic from 'scrollmagic';
import face from '../images/face.png';
import insta from '../images/insta.png';
import what from '../images/what.png';
import AOS from 'aos'


const Menuuu = () => {
 
   
  useEffect(()=>{
    AOS.init({duration:2000})
  })
  return (
  
    <div>
        <div className="main-img-container semain1">
      <div className="main-img semain">
        <div className="main-cnt chalich">
          <div className="main-rnd">
            <img className="main-face" src={face} alt="" />
          </div>
          <div className="main-rnd">
            <img className="main-face" src={insta} alt="" />
          </div>
          <div className="main-rnd">
            <img className="main-face" src={what} alt="" />
          </div>
        </div>

        <div className="main-cnt-2 ilavayi">
          {/* <span className="main2-soltn">
            <div className="main-kia-1"></div>
            Perfect Solutions for Your Mind
          </span> */}
          <span className="main2-soltn1 premium">
           OUR SERVICES
           </span>
          <span className="main2-soltn2">
            Doidunt eget semper nec ruam sed hendrerit morbi aeu feliseao
          </span>
       
            {/* <div className='look' data-aos='fade-up'>
              <button className='kbtn'>Book Appoinment</button>
              <button className='kbtn kbtn1'>Read More</button>
            </div> */}
            {/* <div className='mnv' data-aos="fade-up"
     data-aos-anchor-placement="top-center">
</div> */}
        </div>

{/* 
        <div className='main3-cards'>
                <div className='main-card-side1'>
                    <div className='main-card-side1-1'></div>
                    <img className='main-card-side-1-1-1' src="https://html.designingmedia.com/medunit/assets/images/banner-right-img1.png" alt="" />
                    <div className='main-card-side1-2'></div>
                </div>
                <div>
                  <img className='main-card-img1' src="https://html.designingmedia.com/medunit/assets/images/banner-right-img2.png" alt="" />
                  <img className='main-card-img2' src="https://html.designingmedia.com/medunit/assets/images/banner-right-img3.png" alt="" />
                </div>
        </div> */}



      </div>
    
    </div>
    </div>
  
  );
};

export default Menuuu;
