import React from "react";
import "./Testimonial.css";
// import apic from "../../Images/happyplp.jpg";
import double from "../../images/double-quotes.png";
import '../../AboutPic/AboutPic.css'
import { PiQuotesBold } from "react-icons/pi";

const Testimonial = () => {
  return (
    // <div className='Testimonial'>

    <div className="testmain">
      <div className="testmain-pic">
        {/* <img src={apic} alt="" srcset="" /> */}
        <div className="about-opacity"></div>
        <div className="about-texts">
    <h1>Testimonial</h1>
    <p>Discover heartfelt stories from our clients and staff, sharing their experiences with Advance Care Solutions. Explore how we're transforming lives with compassionate care and innovative solutions.</p>
    </div>
      </div>

      <div className="testimonial-data" >
        <div className="testimonial-data-items" >
        <PiQuotesBold style={{color:"#AD88C6"}} />
          <span>
          Advance Care Solutions turned our struggling care home around in no time. With their expert guidance and support, we were able to address compliance issues and improve our CQC rating significantly. Highly recommend their services!
          </span>

          <span>Sarah M</span>
          <span>Care Home Owner</span>
        </div>
        <div className="testimonial-data-items">
        <PiQuotesBold style={{color:"#AD88C6"}} />
                  <span>
          Thanks to Advance Care Solutions, our care home now operates like a well-oiled machine. Their in-home management services provided us with a skilled interim manager who elevated our standards and implemented effective systems. Grateful for their professionalism and expertise
          </span>

          <span>John P</span>
          <span> Care Home Manager</span>
        </div>
        <div className="testimonial-data-items">
        <PiQuotesBold style={{color:"#AD88C6"}} />
                  <span>
          Advance Care Solutions' crisis management service saved our care home from a potential disaster. Their swift response and strategic handling of staffing issues prevented a crisis and ensured continuity of care for our residents. Can't thank them enough for their support during a challenging time.
          </span>
          <span> Emily T</span>
          <span>Care Home Administrator</span>
        </div>
        <div className="testimonial-data-items">
        <PiQuotesBold style={{color:"#AD88C6"}} />
                  <span>
          The mentoring and coaching program offered by Advance Care Solutions has been invaluable for our staff development. Their experienced consultants provided tailored guidance and training, empowering our team to excel in their roles and deliver exceptional care to our residents.
          </span>
          <span> Mark L</span>
          <span>Senior Caregiver</span>
        </div>
      </div>
    </div>

    // </div>
  );
};

export default Testimonial;
