import React, { useEffect } from "react";
import "./OurHome2.css";
import { LiaBedSolid } from "react-icons/lia";
import { FaPeopleRoof } from "react-icons/fa6";
import { LuParkingCircle } from "react-icons/lu";
import { CiClock2 } from "react-icons/ci";
import { Gallery } from "react-grid-gallery";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Import the gallery CSS
import AOS from 'aos'

const OurHome2 = () => {

  useEffect(()=>{
    AOS.init({duration:1000})
  })

  const images = [
    {
      original:
        "https://images.pexels.com/photos/276551/pexels-photo-276551.jpeg?auto=compress&cs=tinysrgb&w=600",
      thumbnail:
        "https://images.pexels.com/photos/276551/pexels-photo-276551.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      original:
        "https://plus.unsplash.com/premium_photo-1664476958156-367e84136f26?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGhvbWUlMjBjYXJlfGVufDB8fDB8fHww",
      thumbnail:
        "https://plus.unsplash.com/premium_photo-1664476958156-367e84136f26?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGhvbWUlMjBjYXJlfGVufDB8fDB8fHww",
    },
    {
      original:
        "https://plus.unsplash.com/premium_photo-1663054397533-2a3fb0cab5de?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjV8fGhvbWUlMjBjYXJlfGVufDB8fDB8fHww",
      thumbnail:
        "https://plus.unsplash.com/premium_photo-1663054397533-2a3fb0cab5de?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjV8fGhvbWUlMjBjYXJlfGVufDB8fDB8fHww",
    },
  ];

  return (
    <div className="ourhome">
      <div className="ourhome-main2">
        <div className="about-opacity"></div>
        <div className="about-texts" data-aos="fade-up">
          <h1 style={{ textAlign: "center" }}>
            Advance Care Nursing Home In Horsham{" "}
          </h1>
          <p>PROVIDING NURSING CARE & SPECIALIST DEMENTIA CARE IN WORTHING</p>
        </div>
      </div>

      <div className="ourhome-desc"  data-aos="fade-up">
        <span>
        Advance Care Home has been completely redesigned to offer the most luxurious nursing and dementia care facilities, setting a new standard for excellence in the region.
        </span>
      </div>

      <div className="ourhome-profile" data-aos="fade-up" >
        <h2>Advance Care  Nursing Home In Horsham</h2>
        <div className="ourhome-ppls" data-aos="fade-up">
          <div className="ourhome-card">
            <img
              src="https://t4.ftcdn.net/jpg/05/59/46/33/240_F_559463395_dBqVnSCQ479taoyYSaohffGOLQiI3x5w.jpg"
              alt=""
              srcset=""
            />
            <span style={{ fontSize: "20px" }}>Bernal</span>
            <span> MANAGER</span>
          </div>
          <div className="ourhome-card">
            <img
              src="https://t3.ftcdn.net/jpg/02/40/30/56/240_F_240305699_X3ky3vcpPRDNBtg1qjmLW7ntzPGU0eGN.jpg"
              alt=""
              srcset=""
            />
            <span style={{ fontSize: "20px" }}>chris</span>
            <span> DEPUTY</span>
          </div>
          <div className="ourhome-card">
            <img
              src="https://t4.ftcdn.net/jpg/05/80/60/33/240_F_580603305_ysEbDBvHCKM9TyzEINHyW614NWLdTe0b.jpg"
              alt=""
              srcset=""
            />
            <span style={{ fontSize: "20px" }}>Jacob</span>
            <span> MANAGER</span>
          </div>
        </div>
      </div>

      <div className="ourhome-drop">
        <div className="ourhome-drop-desc" data-aos="fade-up">
          <p>
          At Advance Care Solutions Ltd, our team of dedicated professionals exemplifies a commitment to excellence in healthcare management. With profound expertise spanning regional governance, consultancy, crisis intervention, and compliance, we tirelessly support care homeowners, investors, and practitioners in realizing their objectives.

          </p>
          <p>
          Our collaborative approach, coupled with innovative practices and an unwavering dedication to quality, ensures the highest standards of care for residents and exceptional value for our clients. We pride ourselves on fostering partnerships that drive success and deliver superior outcomes in the dynamic field of healthcare.

          </p>

        
        </div>

        <div className="ourhome-care-menu-one1" data-aos="fade-up">
          {/* <span>Need Urgent Help</span> */}

          <div
            class="accordion accordion-flush"
            style={{ color: "black", width: "95%" }}
            id="accordionFlushExample"
          >
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button collapsed button-txt"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Facilities & Services
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  At Advance Care Solutions, we offer a variety of amenities,
                  including a hairdressing salon, a quiet library, and an
                  orangery in our beautiful gardens to enjoy sunny days. <br />
                  <br />
                  Additional facilities include: palliative care, respite care,
                  convalescent care, physiotherapy, a dedicated dementia care
                  unit, the option to have your own GP, the option to bring your
                  own furniture, a non-smoking policy, a lift, wheelchair
                  access, gardens for residents, phone points and mobile access
                  in rooms, television points in rooms, resident internet
                  access, Wi-Fi, Sky TV and digital channels, hydrotherapy
                  suites, a private function room for celebrations, private
                  lounges for residents and families, activity lounges, several
                  acres of gardens and grounds, free secure parking, a
                  chiropodist, monthly Holy Communion, en suite wet rooms, CCTV,
                  and private telephone numbers for each room.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button collapsed button-txt"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Visit
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Feel free to visit at any time. You can also bring along
                  well-behaved family pets. We frequently host activities and
                  entertainers, and you’re always welcome to join without
                  needing an invitation.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed button-txt"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Parking
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Ample parking is available on-site.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFour">
                <button
                  class="accordion-button collapsed button-txt"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  Care Categories
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Registered Care Categories : Dementia – Elderly Care – Younger
                  Adults Specialist Care Categories: Parkinson’s Disease <br />
                  <br />* The Care Quality Commission oversees the registration
                  and inspection of social care services in England.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ourhome-services" data-aos="fade-up">
        <div className="ourhome-services-card">
          <LiaBedSolid style={{ fontSize: "70px", color: "#7469B6" }} />
          <span style={{ fontSize: "20px" }}>Rooms</span>
          <span style={{ color: "#AD88C6" }}> 60 rooms </span>
        </div>
        <div className="ourhome-services-card">
          <FaPeopleRoof style={{ fontSize: "70px", color: "#7469B6" }} />
          <span style={{ fontSize: "20px" }}>Staffs</span>
          <span style={{ color: "#AD88C6" }}> 100 Members </span>
        </div>
        <div className="ourhome-services-card">
          <LuParkingCircle style={{ fontSize: "70px", color: "#7469B6" }} />
          <span style={{ fontSize: "20px" }}>Parking</span>
          <span style={{ color: "#AD88C6" }}> 30 free Parking Bays </span>
        </div>
        <div className="ourhome-services-card">
          <CiClock2 style={{ fontSize: "70px", color: "#7469B6" }} />
          <span style={{ fontSize: "20px" }}>Visiting Hous</span>
          <span style={{ color: "#AD88C6" }}> Welcome All Hours </span>
        </div>
      </div>

      <div className="ourhome-gallery" data-aos="fade-up">
        <span>Advance Care   Gallery</span>
        <ReactImageGallery
          className="image-gallery"
          items={images}
          autoPlay={true}
          slideInterval={3000}
        />
      </div>
    </div>
  );
};

export default OurHome2;