import React from 'react'
import './Main1.css'
import Footer from '../components/Footer'
import Caremenu from './Caremenu'
import Connect from '../components/Connect'
import Maincard from './Maincard'

const Insight = () => {
  return (
    <>
    <div>
        <div className='insights-main'>

          <div className="about-opacity"></div>
          <div className='care-content-container cheriya'>
             <span>Insights</span>
             <p>News, analysis and strategic recommendations to help you deliver the best quality of care for your residents and improve the commercials in your care home/s.</p>
          </div>

    </div>
    <div className='menu1-main' data-aos='fade-up'>
                <span className='menu1-span card-head-shotq'  >Strategic analysis and recommendations for the care home sector</span>
                <span className="card-shot-span1 care-menu-think">We analyse the key challenges facing the care sector as we go into each new year, providing expert strategies for how to tackle them to succeed in the coming months. Our recommendations for the last two years remain as pertinent now as they were when we wrote them. Download them to access our expertise to strengthen your care home/s.</span>
              </div>
      <Maincard/>
    
    </div>
    </>
  )
}

export default Insight