import React from 'react'
import './Main1.css'
import Footer from '../components/Footer'
import Caremenu from './Caremenu'
import Casecard from './Casecard'
import Menu from './Menu'

const Casestudies = () => {
  return (
    <>
    <div>
        <div className='care-main-cqc case-study-main-content'>
          <div className="about-opacity"></div>
          <div className='about-texts'>
             <h1>Case Studies</h1>
           {/* <p>Fulcrum Care helps nursing and care homes in England to improve. Take a look at some of the care homes we have helped.</p> */}
          </div>

    </div>
   <Menu/>
 
    </div>
    </>
  )
}

export default Casestudies