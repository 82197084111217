import React, { useEffect } from 'react'
import '../../AboutPic/AboutPic.css'
import AOS from 'aos'
const JoinOurteam = () => {
  useEffect(()=>{
    AOS.init({duration:1000})
  })
  return (
    <>
    <div className='JoinOurteam'>
    <div className="Ourteam-pic">
        {/* <img src={apic} alt="" srcset="" /> */}
        <div className="about-opacity"></div>
        <div className="about-texts">
    <h1>Join Our Team</h1>
    <p>Are you passionate about making a real difference in the care sector? At Advance Care Solutions Ltd, we're on a mission to empower care homes across the country to thrive and excel. As demand for our services continues to grow, we're seeking dedicated professionals to join our team of care consultants.</p>

    </div>
      </div>
    </div>
    <div>
    <div className='join-our-team1' data-aos='fade-up'>
                <span className='menu1-span card-head-shotq' style={{fontSize:25}} >What We're Looking For</span>
                <span className="card-shot-span1">
At Advance Care Solutions Ltd, we embrace diversity and creativity in our approach to supporting care homes. We believe in delivering tailor-made solutions that meet the unique needs of each client. If you're forward-thinking, passionate about collaboration, and eager to drive positive change in the industry, we want to hear from you.</span>
              </div>



              <div className='join-our-team1' data-aos='fade-up'>
                <span className='menu1-span card-head-shotq' style={{fontSize:25}} >  Benefits of Joining Us</span>
                <span className="card-shot-span1">
              
•	Competitive rates <br />
•	Full expenses policy <br />
•	High-quality work with impactful results <br />
•	Opportunity to contribute to an increasingly recognized brand in the industry <br />
•	Work with providers who have substantial care home portfolios</span>
              </div>     



              <div className='join-our-team1' data-aos='fade-up'>
                <span className='menu1-span card-head-shotq' style={{fontSize:25}} >  Areas of Work</span>
                <span className="card-shot-span1">
              
               
Our consultants are involved in three main areas of work: <br />
1.	Reactive: Crisis management, immediate CQC response, management cover <br />
2.	Proactive: Long-term management support <br />
3.	Value-add: Implementing system changes to improve management structures and commercial outcomes, including new technology integration</span>
              </div>  



               <div className='join-our-team1' data-aos='fade-up'>
                <span className='menu1-span card-head-shotq' style={{fontSize:25}} > Who We're Looking For</span>
                <span className="card-shot-span1">
               
We welcome individuals with previous experience in consultancy, senior management, or expertise within one of our main verticals. Regional experience is a plus, and while exclusivity with Advance Care Solutions Ltd is not required, we expect a commitment to avoiding conflicts of interest.</span>
              </div> 


              <div className='join-our-team1' data-aos='fade-up'>
                <span className='menu1-span card-head-shotq' style={{fontSize:25}} >Join Us Today</span>
                <span className="card-shot-span1">
               Advance Care Solutions Ltd offers opportunities for freelance contractors based anywhere in England. A driving license and willingness to travel are essential. If you're ready to embark on a rewarding journey with a team dedicated to driving positive change in the care sector, contact us today to learn more about working with us.</span>
              </div> 
    </div>
    </>
  )
}

export default JoinOurteam