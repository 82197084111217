import React from "react";
import two from "../images/two.png";
import face from "../images/face.png";
import insta from "../images/insta.png";
import what from "../images/what.png";
import { MdLocationOn } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { BsBrowserChrome } from "react-icons/bs";
import { FaGreaterThan } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigation=useNavigate()
  return (
    <div>
      <div className="ft-main">
        {/* <div className="ft1">


          <div>
            <span>Subscribe To Our Newsletter</span>
          </div>
          <input type="text" placeholder="Your Email Address" name="" id="" />
          <button>Sign Up Now</button>
        </div> */}
        <div className="ft2">
          <div className="ft3">
            <div className="ft4">
              <div className="ft5">
                <img className="ft6" src={two} alt="" />
                <span>Advance Care</span>
              </div>
              <span className="ft7">
                Healthcare Management Solutions offers a comprehensive suite of
                services catering to the needs of Care Home Owners, Investors,
                Banks, and Insolvency Practitioners.
              </span>
              <div className="ft8">
                <div className="main-rnd ft9">
                  <img className="main-face ft10" src={face} alt="" />
                </div>
                <div className="main-rnd ft9">
                  <img className="main-face ft10" src={insta} alt="" />
                </div>
                <div className="main-rnd ft9">
                  <img className="main-face ft10" src={what} alt="" />
                </div>
              </div>
            </div>

            <div className="ft21 foot-margin-top">
              <span className="ft22">Get In Touch</span>
              <div className="ft23">
                <MdLocationOn style={{ color: "white", marginTop: 10 }} />{" "}
                <a className="ft7" href="https://www.google.com/maps?q=Basepoint+Metcalf+Way,+Crawley+RH11+7XX">Basepoint Metcalf Way, Crawley RH11 7XX</a>

              </div>
              <div className="ft23">
                <IoCall style={{ color: "white", marginTop: 10 }} />{" "}
                
                <a className="ft7" href="tel:+07506531060">07506531060</a>

              </div>
              <div className="ft23">
                <MdEmail style={{ color: "white", marginTop: 10 }} />{" "}
                <a className="ft7" href="mailto:info.advancecaresolutions@gmail.com">info.advancecaresolutions@gmail.com</a>
              </div>
              <div className="ft23">
                <BsBrowserChrome style={{ color: "white", marginTop: 10 }} />{" "}
                <span className="ft7"> www.advance-caresolutions.co.uk</span>
              </div>
            </div>

            <div className="ft21">
              <span className="ft22">Quick Links</span>
              <div className="ft23">
                <FaGreaterThan
                  style={{ color: "white", marginTop: 12, fontSize: 10 }}
                />{" "}
                <span className="ft7" onClick={()=>navigation("/")} >Home</span>
              </div>
              <div className="ft23">
                <FaGreaterThan
                  style={{ color: "white", marginTop: 12, fontSize: 10 }}
                />{" "}
                <span className="ft7" onClick={()=>navigation("/Ourteam")}>About Us</span>
              </div>
              <div className="ft23">
                <FaGreaterThan
                  style={{ color: "white", marginTop: 12, fontSize: 10 }}
                />{" "}
                <span className="ft7" onClick={()=>navigation("/CrisisManagement")}> Services</span>
              </div>
              <div className="ft23">
                <FaGreaterThan
                  style={{ color: "white", marginTop: 12, fontSize: 10 }}
                />{" "}
                <span className="ft7" onClick={()=>navigation("/care")}> CQC Dashboards</span>
              </div>
              <div className="ft23">
                <FaGreaterThan
                  style={{ color: "white", marginTop: 12, fontSize: 10 }}
                />{" "}
                <span className="ft7" onClick={()=>navigation("/contacts")}>Contact Us</span>
              </div>
            </div>
          </div>
        </div>

        <div className="ft-babin">
          <span>Advance Care copyright © 2024. All Rights Reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
