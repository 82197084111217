import React, { useEffect, useState } from "react";
import one from "../images/one.jpg";
import two from "../images/two.png";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { IoCall } from "react-icons/io5";
import face from "../images/face.png";
import insta from "../images/insta.png";
import what from "../images/what.png";
import { FiAlignJustify } from "react-icons/fi";
import AOS from "aos";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineKeyboardArrowUp, MdOutlineLocalPhone } from "react-icons/md";
import { IoEarth, IoMenu } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
const Nav = ({ isLastSixCases }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const [issub, setIssub] = useState(false);
  const [content, setContent] = useState(false);


  const [home, setHome] = useState(false);

  // const [about,setAbout]=useState(false)
  const [cqc, setCqc] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 745) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 400, easing: "ease-in-out", delay: 0 });
  }, []);

  const [offcanvas, setOffcanvas] = useState(false);
  const [about, setabout] = useState(false);
  const [product, setproduct] = useState(false);
  const [price, setprice] = useState(false);

  const handleOpen = () => {
    setIssub(!issub);
  };
  const handleAbout = () => {
    setabout(!about);
  };
  const handleProduct = () => {
    setproduct(!product);
  };
  const handlePrice = () => {
    setprice(!price);
  };
  const handleHome = () => {
    setHome(!home);
  };

  return (
    // <div className={Navbar ${isScrolled ? 'scrolled' : ''}}>
    <div className="navbar-main1">
      <div>
        <div className="nav-sub-at">
          <div className="nav-sub-at-one litiha">
            <IoCall style={{ color: "white", fontSize: 25 }} />
            <span>Talk to us today | 07506531060</span>
          </div>

          <span className="nav-sub-at-one-span"></span>
          <div className="nav-sub-at-one gilll1">
            <img className="gill" src={what} alt="" />
            <img className="gill" src={face} alt="" />
            <img className="gill" src={insta} alt="" />
          </div>
        </div>

        <div
          className={`nav-main ${isScrolled ? "scrolled" : ""} ${
            isLastSixCases ? "last-six-cases" : ""
          }`}
        >
          <div className="nav-sub" onClick={() => navigate("/")}>
            <img className="nav-icon" src={two} alt="" />
            <span className="nav-span">ADVANCE CARE SOLUTIONS LTD</span>
          </div>

          <div className="nav-sub1">
            <Link className="jiji" to="/">
              {" "}
              <span className="nav-sub-span">Home</span>
            </Link>
            <Link className="jiji">
              {" "}
              <span className="home home11 nav-sub-span">
                Our Home
                <div className="home1">
                  <div className="home2">
                    <Link to="/ourhome" className="jiji nav-span-link2">
                      {" "}
                      <span>East Grinstead</span>
                    </Link>
                    <Link to="/ourhome2" className="jiji nav-span-link2">
                      {" "}
                      <span>Horsham</span>
                    </Link>
             
                    {/* < Link to="/Consultancy" className='jiji'>   <span>Consultancy</span></Link> */}
                  </div>
                </div>
              </span>{" "}
            </Link>
            <Link className="jiji">
              {" "}
              <span className="home home11 nav-sub-span">
                About
                <div className="home1">
                  <div className="home2">
                    <Link to="/Ourteam" className="jiji nav-span-link2">
                      {" "}
                      <span>Our Team</span>
                    </Link>
                    <Link to="/Partnership" className="jiji nav-span-link2">
                      {" "}
                      <span>Partnership</span>
                    </Link>
                    <Link to="/Testimonial" className="jiji nav-span-link2">
                      {" "}
                      <span>Testimonial</span>
                    </Link>
                    <Link to="/Joinourteam" className="jiji nav-span-link2">
                      {" "}
                      <span>Join our Team</span>
                    </Link>
                    {/* < Link to="/Consultancy" className='jiji'>   <span>Consultancy</span></Link> */}
                  </div>
                </div>
              </span>{" "}
            </Link>

            <Link className="jiji">
              {" "}
              <span className="home home11 nav-sub-span">
                Services
                <div className="home1">
                  <div className="home2">
                    <Link
                      to="/CrisisManagement"
                      className="jiji nav-span-link2"
                    >
                      {" "}
                      <span>Consultancy & Support</span>
                    </Link>
                    <Link to="/CQCChallenges" className="jiji nav-span-link2">
                      {" "}
                      <span>CQC Challenges</span>
                    </Link>
                    <Link to="/Governance" className="jiji nav-span-link2">
                      {" "}
                      <span>Care Home Governance & Compliance</span>
                    </Link>
                    <Link to="/crisis1" className="jiji nav-span-link2">
                      {" "}
                      <span>Care Home Crisis Management</span>
                    </Link>
                    <Link to="/Inhome" className="jiji nav-span-link2">
                      {" "}
                      <span>In-Home Management</span>
                    </Link>
                    <Link to="/Mentoring" className="jiji nav-span-link2">
                      {" "}
                      <span>Mentoring & Coaching</span>
                    </Link>
                    <Link to="/DementiaSupport" className="jiji nav-span-link2">
                      {" "}
                      <span>
                      Dementia Behavior Support
                      </span>
                    </Link>
                    <Link to="/PersonCentered" className="jiji nav-span-link2">
                      {" "}
                      <span>Advance QMS</span>
                    </Link>
                  </div>
                </div>
              </span>
            </Link>

            <Link className="jiji">
              {" "}
              <span className="home home11 nav-sub-span">
                CQC Dashboard
                <div className="home1">
                  <div className="home2">
                    <Link to="/care" className="jiji nav-span-link2">
                      {" "}
                      <span style={{ color: "black", fontSize: 14 }}>
                        CQC Inspection & Audit
                      </span>
                    </Link>
                    <Link to="/case" className="jiji nav-span-link2">
                      {" "}
                      <span style={{ color: "black", fontSize: 14 }}>
                        Mock Inspection
                      </span>
                    </Link>
                    <Link to="/league" className="jiji nav-span-link2">
                      {" "}
                      <span style={{ color: "black", fontSize: 14 }}>FAQs</span>
                    </Link>
                  </div>
                </div>
              </span>
            </Link>

            <Link to="/casestudy" className="jiji">
              {" "}
              <span className="nav-sub-span">Case Studies</span>
            </Link>
            {/* <Link to="/insight" className="jiji">
              {" "}
              <span className="nav-sub-span">Insights</span>
            </Link> */}
            <Link className="jiji" to="/contacts">
              {" "}
              <span className="nav-sub-span">Contact Us</span>
            </Link>
            {/* <div className='nav-call'>
                    
               <div className='nav-call-img'> <img src="https://html.designingmedia.com/medunit/assets/images/phone.png" alt="" /></div>
               <div className='nav-call-main1'>
                <span className='call-us'>call us now</span>
                <span className='call-no'>9947307624</span>
               </div>
                </div> */}
          </div>

          <div className="nav-medaiq-icon" onClick={handleOpen}>
            <FiAlignJustify style={{ fontSize: "45px", color: "white" }} />
          </div>
        </div>
      </div>
      {/* {issub &&<div className="issubmainmain">
<div className="issubmain" data-aos='fade-left'>
  <RxCross2 style={{fontSize:"35px" ,padding:"5px"}}   onClick={()=>setIssub(false)} />
  <button className="cnt-btn-q" onClick={() => { navigate("/"); setIssub(false); }}>Home</button>
   <button className="cnt-btn-q" onClick={()=>setAbout(!about)}>About
   {about&& <>
    <div className="dropdown-content1" data-aos='fade-left'>
    <a onClick={() => { navigate("/Ourteam"); setIssub(false); }} href="#">Our Team</a>
<a onClick={() => { navigate("/Partnership"); setIssub(false); }} href="#">Partnership</a>
<a onClick={() => { navigate("/Testimonial"); setIssub(false); }} href="#">Testimonial</a>
<a onClick={() => { navigate("/Joinourteam"); setIssub(false); }} href="#">Join our Team</a>

  
        </div>
   </>}
   </button>
   <button className="cnt-btn-q" onClick={()=>setContent(!content)}>Services
   {content&& <>
    <div className="dropdown-content1"  data-aos='fade-left'>
    <a onClick={() => { navigate("/CrisisManagement"); setIssub(false); }} href="#">Consultancy & Support</a>
<a onClick={() => { navigate("/CQCChallenges"); setIssub(false); }} href="#">CQC Challenges</a>
<a onClick={() => { navigate("/Governance"); setIssub(false); }} href="#">Care Home Governance & Compliance</a>
<a onClick={() => { navigate("/crisis1"); setIssub(false); }} href="#">Care Home Crisis Management</a>
<a onClick={() => { navigate("/Inhome"); setIssub(false); }} href="#">In-Home Management</a>
<a onClick={() => { navigate("/Mentoring"); setIssub(false); }} href="#">Mentoring & Coaching</a>
<a onClick={() => { navigate("/DementiaSupport"); setIssub(false); }} href="#">Dementia-Behavioural & Psychological Management Support Services</a>
<a onClick={() => { navigate("/PersonCentered"); setIssub(false); }} href="#">Advance QMS</a>

       
        </div>
   </>}
   </button>

   <button className="cnt-btn-q" onClick={()=>setCqc(!cqc)}>  CQC Dashboard
   {cqc&& <>
    <div className="dropdown-content1" data-aos='fade-left'>
    <a onClick={() => { navigate("/care"); setIssub(false); }} href="#">CQC Inspection & Audit</a>
<a onClick={() => { navigate("/case"); setIssub(false); }} href="#">Mock Inspection</a>
<a onClick={() => { navigate("/league"); setIssub(false); }} href="#">FAQs</a>

  
        </div>
   </>}
   </button>

   <button className="cnt-btn-q" onClick={() => { navigate("/casestudy"); setIssub(false); }}>Case Studies</button>
<button className="cnt-btn-q" onClick={() => { navigate("/contacts"); setIssub(false); }}>Contact Us</button>


</div>
</div>} */}

      {issub && (
        <div className="issubmainmain">
          <div data-aos="fade-right" className="p-3 offcanvas-div">
            <div className="container-fluid d-flex justify-content-between  align-items-center">
              <span style={{ fontSize: "30px" }}></span>
              <button
                className="btn-close  d-flex align-items-end sidebar-cfc"
                onClick={handleOpen}
              ></button>
            </div>

            <div className="offcanvas-body d-flex flex-column gap-4">
              {/* Offcanvas content goes here */}
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/");
                  setIssub(false);
                }}
              >
                Home
              </span>

              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={handleHome}
              >
                Our Home{" "}
                {home ? (
                  <MdOutlineKeyboardArrowUp style={{ fontSize: "20px" }} />
                ) : (
                  <MdOutlineKeyboardArrowDown style={{ fontSize: "20px" }} />
                )}
                {home && (
                  <div
                  style={{ borderBottom: "0.5px solid grey" }}
                    className=" gap-2 d-flex flex-column p-2 "
                    data-aos="fade-up"
                  >
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/ourhome");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      East Grinstead
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/ourhome2");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      Horsham
                    </a>
                    
                    
                  </div>
                )}
              </span>
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={handleAbout}
              >
                About Us{" "}
                {about ? (
                  <MdOutlineKeyboardArrowUp style={{ fontSize: "20px" }} />
                ) : (
                  <MdOutlineKeyboardArrowDown style={{ fontSize: "20px" }} />
                )}
                {about && (
                  <div
                  style={{ borderBottom: "0.5px solid grey" }}
                    className=" gap-2 d-flex flex-column p-2 "
                    data-aos="fade-up"
                  >
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/Ourteam");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      Our Team
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/Partnership");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      Partnership
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/Testimonial");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      Testimonial
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/Joinourteam");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      Join our Team
                    </a>
                  </div>
                )}
              </span>
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={handleProduct}
              >
                Services{" "}
                {product ? (
                  <MdOutlineKeyboardArrowUp style={{ fontSize: "20px" }} />
                ) : (
                  <MdOutlineKeyboardArrowDown style={{ fontSize: "20px" }} />
                )}
                {product && (
                  <div
                  style={{ borderBottom: "0.5px solid grey" }}
                  className=" gap-2 d-flex flex-column p-2 "
                    data-aos="fade-up"
                  >
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/CrisisManagement");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      Consultancy & Support
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/CQCChallenges");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      CQC Challenges
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/Governance");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      Care Home Governance & Compliance
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/crisis1");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      Care Home Crisis Management
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/Inhome");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      In-Home Management
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/Mentoring");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      Mentoring & Coaching
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/DementiaSupport");
                        setIssub(false);
                      }}
                      href="#"
                    >
                     Dementia Behavior Support
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/PersonCentered");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      Advance QMS
                    </a>
                  </div>
                )}
              </span>
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={handlePrice}
              >
                CQC Dashboard{" "}
                {price ? (
                  <MdOutlineKeyboardArrowUp style={{ fontSize: "20px" }} />
                ) : (
                  <MdOutlineKeyboardArrowDown style={{ fontSize: "20px" }} />
                )}
                {price && (
                  <div
                    style={{ borderBottom: "0.5px solid grey" }}
                    className=" gap-2 d-flex flex-column p-2 "
                    data-aos="fade-up"
                  >
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/care");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      CQC Inspection & Audit
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/case");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      Mock Inspection
                    </a>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/league");
                        setIssub(false);
                      }}
                      href="#"
                    >
                      FAQs
                    </a>
                  </div>
                )}
              </span>

              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/casestudy");
                  setIssub(false);
                }}
              >
                Case Studies
              </span>
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/contacts");
                  setIssub(false);
                }}
              >
                Contact Us
              </span>

              {/* <button className='p-2 btn-dark border-0 rounded get-btn'>Get a quote</button> */}
              {/* <span style={{ fontSize: "15px", fontWeight: "500", cursor: "pointer" }}><MdOutlineLocalPhone style={{ fontSize: "20px", fontWeight: "500" }} /> 033 322 4439</span> */}
              {/* <IoEarth style={{ fontSize: "25px", fontWeight: "500", cursor: "pointer" }} /> */}
            </div>
          </div>
        </div>
      )}

      <Outlet />
    </div>
  );
};

export default Nav;
