import React from 'react'
import './Main1.css'
import Footer from '../components/Footer'
import Caremenu from './Caremenu'
import Connect from '../components/Connect'

const Contacts = () => {
  return (
    <>
    <div>
        <div className=' contacts-main-c11'>
          <div className="about-opacity"></div>
          <div className='about-texts '>
             <h1>Contact Us</h1>
             <p>Ready to elevate your care home to new heights? <br /> Contact Advance Care Solutions Ltd today to learn more about our comprehensive suite of services and how we can support your success.</p>
          </div>

    </div>
   
    <Connect/>
    
    </div>
    </>
  )
}

export default Contacts