import React from 'react'
import './Main1.css'
import Footer from '../components/Footer'
import Caremenu from './Caremenu'
import According from './According'

const Carecqc = () => {
  return (
    <>
    <div>
        <div className='care-main-cqc'>
          <div className="about-opacity"></div>
          <div className="about-texts">
    <h1>CQC Inspections & Audit</h1>
    <p>Elevating Standards in Care Provision</p>


    </div>

    </div>
    <Caremenu/>
  {/* <According/> */}
    </div>
    </>
  )
}

export default Carecqc