import React, { useEffect } from 'react'
import './InHomeManage.css'
import '../../AboutPic/AboutPic.css'
import AOS from 'aos'

const InHomeManage = () => {
 
    useEffect(()=>{
     AOS.init({duration:1000})
   })
  return (
    <div className='Consultancy'>
          <div className="InHomeManage-pic">
        {/* <img src={apic} alt="" srcset="" /> */}
        <div className="about-opacity"></div>
        <div className="about-texts">
    <h1>In Home Management</h1>
    <p>
    A well-run care home starts with effective management. If you're having trouble finding the right manager or need interim management support, Advance Care Solutions Ltd can assist.
</p>


    </div>
      </div>
   
      <div className="Consultancy-data">
  <h1 data-aos='fade-up'>In-Home Management</h1>

  <p data-aos='fade-up'>
  In-home management services provided by Advance Care Solutions offer comprehensive support to care homes in maintaining efficient operations and delivering high-quality care to residents. Our experienced managers are adept at raising standards, improving procedures, and embedding new systems to ensure the smooth operation of your home.



</p>


<h4 data-aos='fade-up'>Our in-home management services include:</h4>


<h4 data-aos='fade-up'>1.	Interim Management: </h4>

<p data-aos='fade-up'>
When a care home is experiencing a managerial vacancy or temporary absence, we provide experienced interim managers to ensure continuity of leadership and smooth day-to-day operations.
</p>


<h4 data-aos='fade-up'>2.	Performance Improvement: </h4>
<p data-aos='fade-up'> 
Our management team works closely with care home staff to identify areas for improvement and implement strategies to enhance overall performance. This may involve streamlining processes, optimizing resource allocation, and implementing best practices.
</p>
<h4 data-aos='fade-up'>3.	Staff Training and Development:</h4>
<p data-aos='fade-up'>
We offer training and development programs tailored to the specific needs of care home staff, including leadership training, clinical skills development, and compliance training. Our goal is to empower staff with the knowledge and skills needed to provide exceptional care to residents.
</p>
<h4 data-aos='fade-up'>4.	Quality Assurance:</h4>
<p data-aos='fade-up'>
We assist care homes in implementing quality assurance frameworks and processes to monitor and evaluate the quality of care delivered. This includes conducting regular audits, inspections, and reviews to identify areas for improvement and ensure compliance with regulatory standards.
</p>

<h4 data-aos='fade-up'>
  </h4>
  <p  data-aos='fade-up'>
  In a crisis or emergency, our management team provides immediate support and guidance to address the issue effectively. We work to mitigate risks, minimize disruptions, and ensure the safety and well-being of residents and staff.
</p>


<h4 data-aos='fade-up'>5.	Operational Support: </h4>
<p data-aos='fade-up'>
Our management team offers operational support to care homes in various areas, including staffing, budgeting, scheduling, and facility management. We help optimize operational processes to enhance efficiency and maximize resources.
</p>

<p data-aos='fade-up'>
By partnering with Advance Care Solutions for in-home management services, care homes can benefit from our expertise, experience, and commitment to excellence. We provide tailored solutions to meet each care home's unique needs, ensuring they are well-equipped to provide the highest standard of care to residents.
</p>
</div>

    </div>
  )
}

export default InHomeManage