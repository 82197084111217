import React from 'react'
import './Consultancy.css'
import '../../AboutPic/AboutPic.css'

const Consultancy = () => {
  return (
    <div className='Consultancy'>
          <div className="Consultancy-pic">
        {/* <img src={apic} alt="" srcset="" /> */}
        <div className="about-opacity"></div>
        <div className="about-texts">
    <h1>Consultancy</h1>
    <p>CQC ratings have a huge impact on your care home business. If your home has received a ‘poor’ or ‘requires improvement’ rating, then you are probably in a panic. After all, you could stand to lose a lot of money.</p>


    </div>
      </div>
   
    <div className="Consultancy-data">
        <h1>Care Home Consultancy Experts</h1>

        <p>Your local authority, the NHS and CCG will stop referring residents to you. And you won’t attract new, private residents either. No-one would choose a low-rated home for their elderly relative.</p>

<p>Going from a green to an amber rating could lose you as much as £200k in profitability. And the value of your home could drop by as much as eight times.</p>
<p>So it is absolutely in your interests to get your rating back up again. And fast. That’s where Fulcrum Care can help.</p>
<p>Our consultancy services can set you right again. And we’re known for turning around homes very quickly too. We will give you a step-by-step plan that will get your home rated highly again.</p>
<p>We will diagnose your issues and then work with you to resolve them. With leadership advice, training or the implementation of best practices, we can help your manager and staff maintain a safe, clean, well-run and happy care home.</p>
<p>We can audit your person-centred care plans, identify where any mistakes are being made and put improvements in place. We talk to your staff, family members and residents, capturing their issues and mentoring your manager and team so that they can improve.</p>
<p>Our project management skills will help break daunting care plans down for your team. And we can help manage expectations of other stakeholders, the public and your home’s neighbours.</p>
<p>And if you don’t have a suitable manager, we can provide our in-home management services as a stop-gap until you find someone suitable.</p>
<p>You may not think you need a consultancy to help you out. But we can see things from an experienced and unbiased perspective.</p>
<p>If the panic is rising, give us call on 020 3411 4014 or drop us an email. We’ll be pleased to speak to you or drop in for a chat. You’ll soon be feeling calmer. And we’ll have your home back into the green rating zone again very soon.</p>
    </div>


    </div>
  )
}

export default Consultancy