import React from 'react'
// import careicon from '../../Images/about1.jpg'
import './Partnership.css'
import '../../AboutPic/AboutPic.css'

const Partnership = () => {
  return (
    <div className='Partnership'>
       
       <div className="AboutPic">
        {/* <img src={careicon} alt="" srcset="" /> */}
        <div className="about-opacity"></div>
        <div className="about-texts">
        <h1>Partnership</h1>
        <p>We are proud to be affiliated with a number of great companies sharing our passion for providing care homes with the tools and procedures they need to succeed.</p>
    </div>
      </div>
      {/* <div className="partnership-data">
      <div className="partnership-card">
        <img src="https://fulcrumcareconsulting.com/wp-content/uploads/2022/07/oach.png" alt="" />
        <h3>Ownacarehome</h3>
        <span>The Ownacarehome website offers solutions and business growth services for care home operators.

The care sector specialist companies that form the Ownacarehome group have extensive knowledge and experience, delivering outstanding results for care home operators to achieve their full potential and increase sustainability for the future.</span>

      </div>
      <div className="partnership-card">
        <img src="https://fulcrumcareconsulting.com/wp-content/uploads/2022/08/The-Care-Workers-Charity-Logo-with-name-002.png" alt="" />
        <h3>Ownacarehome</h3>
        <span>The Ownacarehome website offers solutions and business growth services for care home operators.

The care sector specialist companies that form the Ownacarehome group have extensive knowledge and experience, delivering outstanding results for care home operators to achieve their full potential and increase sustainability for the future.</span>
      </div>
      <div className="partnership-card">
        <img src="https://fulcrumcareconsulting.com/wp-content/uploads/2022/08/new-say-so-logo-plus-strap.png" alt="" />
        <h3>Ownacarehome</h3>
        <span>The Ownacarehome website offers solutions and business growth services for care home operators.

The care sector specialist companies that form the Ownacarehome group have extensive knowledge and experience, delivering outstanding results for care home operators to achieve their full potential and increase sustainability for the future.</span>
      </div>
      <div className="partnership-card">
        <img src="https://fulcrumcareconsulting.com/wp-content/uploads/2022/08/SCA.png" alt="" />
        <h3>Ownacarehome</h3>
        <span>The Ownacarehome website offers solutions and business growth services for care home operators.

The care sector specialist companies that form the Ownacarehome group have extensive knowledge and experience, delivering outstanding results for care home operators to achieve their full potential and increase sustainability for the future.</span>
      </div>
      <div className="partnership-card">
        <img src="https://fulcrumcareconsulting.com/wp-content/uploads/2022/08/SCA.png" alt="" />
        <h3>Ownacarehome</h3>
        <span>The Ownacarehome website offers solutions and business growth services for care home operators.

The care sector specialist companies that form the Ownacarehome group have extensive knowledge and experience, delivering outstanding results for care home operators to achieve their full potential and increase sustainability for the future.</span>
      </div>
      </div> */}


    </div>
  )
}

export default Partnership