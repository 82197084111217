import React from 'react'

const Care = () => {
  return (
    <div className='babin-aparathm'>
        <span className='babin-gundan'>How We Proceed at <span className='babin-jazi'> Care?</span></span>
        <span className='babin-atti'>Every senior we work with has a unique personality, circumstance, and unique care needs. During our consultation we provide a thorough explanation of our services, and offer you guidance and assistance in choosing what option is best for you or your loved one.</span>
    </div>
  )
}

export default Care