import React, { useEffect, useState } from 'react';
import { IoPersonSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { MdCalendarToday } from "react-icons/md";
import a1 from "../MainFolder/img1/smartphone (1).png";
import a12 from "../MainFolder/img1/new-email (1).png";
import a13 from "../MainFolder/img1/pin (1) (1).png";
import AOS from 'aos';
import 'aos/dist/aos.css';

import '../App.css'

const Connect = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
   });
 };

 const handleSubmit = async (e) => {
   e.preventDefault();

   try {
     const response = await fetch('https://script.google.com/macros/s/AKfycbx21f8pH_kn6dT2qQt53e4tDyrlYGgzjgYdFICXP9b3D9qFLBbpDMveErQ2crq51X6vCQ/exec', {
       method: 'POST',
       headers: {
         'Content-Type': 'application/x-www-form-urlencoded'
       },
       body: new URLSearchParams(formData).toString()
     });

     const result = await response.json();

     if (response.ok) {
       alert('Form submitted successfully');
       window.location.reload();
     } else {
       throw new Error(result.error || 'Something went wrong');
     }
   } catch (error) {
     alert(`Something went wrong: ${error.message}`);
     console.error(error);
   }
 };

 return (
   <div className='cb-main'>
     <div className='cb-side-card'>
       <div className='cb-side-card-1' data-aos='fade-up'>
         <img className='cb-side-images' src={a13} alt="Location" />
         <div className='cb-side-card-subdiv'>
           <span>Location:</span>
           <span>Basepoint Metcalf Way. Crawley RH117XX</span>
         </div>
       </div>
       <div className='cb-side-card-1' data-aos='fade-up'>
         <img className='cb-side-images' src={a1} alt="Phone" />
         <div className='cb-side-card-subdiv'>
           <span>Phone:</span>
           <span>07506531060</span>
         </div>
       </div>
       <div className='cb-side-card-1' data-aos='fade-up'>
         <img className='cb-side-images' src={a12} alt="Email" />
         <div className='cb-side-card-subdiv'>
           <span>Email:</span>
           <span>info.advancecaresolutions@gmail.com</span>
         </div>
       </div>
     </div>
     <form className='cb2' onSubmit={handleSubmit}>
       <span className='cb4-one' data-aos='fade-up'>How can we help?</span>
       <span className='cb4' data-aos='fade-up'>Send us a Message</span>
       <span className='cb5' data-aos='fade-up'>Please complete and submit the form below and we will be happy to help you.</span>
       <div className='cb9'>
         <input type="hidden" name="formDataNameOrder" value='["message", "name", "email"]' />
         <div className='cb7' data-aos='fade-up'>
           <input className='cb8' type="text" placeholder='Your Name:' id="name" name="name" value={formData.name} onChange={handleChange} required />
         </div>
         <div className='cb7' data-aos='fade-up'>
           <input className='cb8' type="email" placeholder='Email Address:' name="email" value={formData.email} onChange={handleChange} required />
         </div>
         <div className='cb7221' data-aos='fade-up'>
           <textarea className='cb82221' type="text" placeholder='Message' id="message" name="message" value={formData.message} onChange={handleChange} required />
         </div>
       </div>
       <button className='cb10' data-aos='fade-up'>Send Message</button>
     </form>
   </div>
 );
};

export default Connect;
















// import React, { useEffect } from 'react'
// import { IoPersonSharp } from "react-icons/io5";
// import { MdEmail } from "react-icons/md";
// import { MdCalendarToday } from "react-icons/md";
// import AOS from 'aos'
// const Connect = () => {
//    useEffect(()=>{
//     AOS.init({duration:1000})
//   })
//   return (
//     <>
  
//     <div className='cb-main'>
//         <div className='cb2'>
//             <span className='cb4'  data-aos='fade-up'>How can we help?</span>
//             <span className='cb5'  data-aos='fade-up'>Please complete and submit the form below and we will be happy to help you.</span>

//             <div className='cb9'>
//                 <div className='cb7'  data-aos='fade-up'><input className='cb8' type="text" placeholder='Your Name:' /><IoPersonSharp style={{color:"white"}}/></div>
//                 <div className='cb7'  data-aos='fade-up'><input className='cb8' type="text" placeholder='Email Address:' /><MdEmail  style={{color:"white"}}/></div>
//                 <div className='cb7'  data-aos='fade-up'><input className='cb8' type="text" placeholder='Phone Number' /><MdCalendarToday style={{color:"white",marginLeft:'70px'}}/></div>
//                 <div className='cb7'  data-aos='fade-up'><input className='cb8' type="text" placeholder='Your Name' /><IoPersonSharp style={{color:"white"}}/></div>
//                 <div className='cb7'  data-aos='fade-up'><input className='cb8' type="text" placeholder='Your Name' /><IoPersonSharp style={{color:"white"}}/></div>
//                 <div className='cb7'  data-aos='fade-up'><input className='cb8' type="text" placeholder='Your Name' /><IoPersonSharp style={{color:"white"}}/></div>
//             </div>

//             <button className='cb10'  data-aos='fade-up'>Make a Connection</button>

//         </div>
//         <img className='cb1' src="https://allsufficienthomecare.com/staging/6693/wp-content/uploads/2021/04/Caregivers.jpg" alt="" />
//     </div>
//     </>
//   )
// }

// export default Connect