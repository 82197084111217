import React from 'react'
import Menuuu from './Menuuu'
import Content from './Content'
import Footer from '../components/Footer'
import Care from './Care'
import Connectjs from './Connectjs'


const Service = () => {
  return (
    <>
  <Menuuu/>
  <Care/>
 {/* <Connectjs/> */}
 <Content/>
 <Footer/>
    </>
  )
}

export default Service