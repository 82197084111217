import React, { useEffect } from 'react'
import './OurHome.css'
import { LiaBedSolid } from 'react-icons/lia'
import { FaPeopleRoof } from 'react-icons/fa6'
import { LuParkingCircle } from 'react-icons/lu'
import { CiClock2 } from 'react-icons/ci'
import { Gallery } from 'react-grid-gallery'
import ReactImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'; // Import the gallery CSS
import AOS from 'aos'


const OurHome = () => {

  useEffect(()=>{
    AOS.init({duration:1000})
  })


    const images = [
        {
          original: "https://images.unsplash.com/photo-1501183638710-841dd1904471?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGhvbWUlMjBpbmRlcmlhcnxlbnwwfHwwfHx8MA%3D%3D",
          thumbnail: "https://images.unsplash.com/photo-1501183638710-841dd1904471?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGhvbWUlMjBpbmRlcmlhcnxlbnwwfHwwfHx8MA%3D%3D",
        },
        {
          original: "https://images.unsplash.com/photo-1516455590571-18256e5bb9ff?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGhvbWUlMjBpbmRlcmlhcnxlbnwwfHwwfHx8MA%3D%3D",
          thumbnail: "https://images.unsplash.com/photo-1516455590571-18256e5bb9ff?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGhvbWUlMjBpbmRlcmlhcnxlbnwwfHwwfHx8MA%3D%3D",
        },
        {
          original: "https://images.unsplash.com/photo-1484154218962-a197022b5858?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGhvbWUlMjBpbmRlcmlhcnxlbnwwfHwwfHx8MA%3D%3D",
          thumbnail: "https://images.unsplash.com/photo-1484154218962-a197022b5858?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGhvbWUlMjBpbmRlcmlhcnxlbnwwfHwwfHx8MA%3D%3D",
        },
      ];

  return (
    <div className='ourhome'>

<div className='ourhome-main' data-aos="fade-up">
          <div className="about-opacity"></div>
          <div className="about-texts">
    <h1 style={{textAlign:"center"}}>
Advance Care Nursing Home In East Grinstead</h1>
    <p>LUXURIOUS NURSING HOME WITH DEMENTIA CARE</p>


    </div>

    </div>

    <div className="ourhome-desc" data-aos="fade-up">
    <span>Advance Care Home has undergone a complete transformation to provide premier nursing and dementia care facilities, establishing a new benchmark for excellence in the industry.</span>
    </div>


    <div className="ourhome-profile" data-aos="fade-up">
        <h2>Advance Care Home in East Grinstead</h2>
        <div className="ourhome-ppls">

            <div className="ourhome-card">
                 <img src="https://t4.ftcdn.net/jpg/06/45/77/79/240_F_645777959_fNnaNoeVO4qxCNPW9MWr3gQlPFSGA9yL.jpg" alt="" srcset="" />
                <span style={{fontSize:"20px"}}>Jone Doe</span >
                <span > MANAGER</span>
            </div>
            <div className="ourhome-card">
                 <img src="https://images.unsplash.com/photo-1580894732444-8ecded7900cd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cGVvcGxlJTIwaW4lMjBvZmZpY2V8ZW58MHx8MHx8fDA%3D" alt="" srcset="" />
                <span style={{fontSize:"20px"}}>Lisa</span >
                <span > DEPUTY</span>
            </div>
            <div className="ourhome-card">
                 <img src="https://t4.ftcdn.net/jpg/05/80/60/33/240_F_580603305_ysEbDBvHCKM9TyzEINHyW614NWLdTe0b.jpg" alt="" srcset="" />
                <span style={{fontSize:"20px"}}>Jacob</span >
                <span > MANAGER</span>
            </div>

        </div>
    </div>

    <div className="ourhome-drop" data-aos="fade-up">

        <div className="ourhome-drop-desc" data-aos="fade-up" >
        <p>
          At Advance Care Solutions Ltd, our team of dedicated professionals exemplifies a commitment to excellence in healthcare management. With profound expertise spanning regional governance, consultancy, crisis intervention, and compliance, we tirelessly support care homeowners, investors, and practitioners in realizing their objectives.

          </p>
          <p>
          Our collaborative approach, coupled with innovative practices and an unwavering dedication to quality, ensures the highest standards of care for residents and exceptional value for our clients. We pride ourselves on fostering partnerships that drive success and deliver superior outcomes in the dynamic field of healthcare.

          </p>
             </div>
     


        <div className="ourhome-care-menu-one1" data-aos="fade-up">
            {/* <span>Need Urgent Help</span> */}
    
              <div
                class="accordion accordion-flush"
                style={{ color: "black" ,width:"95%"}}
                id="accordionFlushExample"
              >
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Facilities & Services
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                    At Advance Care Solutions, we offer a variety of amenities, including a hairdressing salon, a quiet library, and an orangery in our beautiful gardens to enjoy sunny days. <br /><br />

Additional facilities include: palliative care, respite care, convalescent care, physiotherapy, a dedicated dementia care unit, the option to have your own GP, the option to bring your own furniture, a non-smoking policy, a lift, wheelchair access, gardens for residents, phone points and mobile access in rooms, television points in rooms, resident internet access, Wi-Fi, Sky TV and digital channels, hydrotherapy suites, a private function room for celebrations, private lounges for residents and families, activity lounges, several acres of gardens and grounds, free secure parking, a chiropodist, monthly Holy Communion, en suite wet rooms, CCTV, and private telephone numbers for each room.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Visit
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                    Feel free to visit at any time. You can also bring along well-behaved family pets. We frequently host activities and entertainers, and you’re always welcome to join without needing an invitation.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Parking
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                    Ample parking is available on-site.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFour">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Care Categories
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                    Registered Care Categories : Dementia – Elderly Care – Younger Adults
Specialist Care Categories: Parkinson’s Disease <br /><br />

* The Care Quality Commission oversees the registration and inspection of social care services in England.
                    </div>
                  </div>
                </div>
              </div>
          
          </div>
          </div> 



          <div className="ourhome-services" data-aos="fade-up">

            <div className="ourhome-services-card">
            <LiaBedSolid style={{fontSize:"70px" ,color:"#7469B6"}}/>
            <span style={{fontSize:"20px",}}>Rooms</span >
                <span style={{color:"#AD88C6"}}> 60 rooms </span>
            </div>
            <div className="ourhome-services-card">
            <FaPeopleRoof style={{fontSize:"70px" ,color:"#7469B6"}}/>
            <span style={{fontSize:"20px",}}>Staffs</span >
                <span style={{color:"#AD88C6"}}> 100 Members </span>
            </div>
            <div className="ourhome-services-card">
            <LuParkingCircle style={{fontSize:"70px" ,color:"#7469B6"}}/>
            <span style={{fontSize:"20px",}}>Parking</span >
                <span style={{color:"#AD88C6"}}> 30 free Parking Bays </span>
            </div>
            <div className="ourhome-services-card">
            <CiClock2 style={{fontSize:"70px" ,color:"#7469B6"}}/>
            <span style={{fontSize:"20px",}}>Visiting Hous</span >
                <span style={{color:"#AD88C6"}}> Welcome All Hours </span>
            </div>

          </div>

<div className="ourhome-gallery" data-aos="fade-up">

    <span>
    Advance Care  Home  Gallery
    </span>
    <ReactImageGallery
          className='image-gallery'
          items={images}
          autoPlay={true}
          slideInterval={3000} // Adjust the interval as needed (in milliseconds)
        />
</div>


    </div>
  )
}

export default OurHome