import React from 'react'
// import careicon from '../../Images/icon-care.png'
import "./OurTeam.css"
import '../../AboutPic/AboutPic.css'

const OurTeam = () => {
  return (
    <div className='OurTeam'>
           <div className="Ourteam-pic">
        {/* <img src={apic} alt="" srcset="" /> */}
        <div className="about-opacity"></div>
        <div className="about-texts">
    <h1>OurTeam</h1>
    <p>Our team at Advance Care Solutions Ltd comprises dedicated professionals committed to excellence in healthcare management. With expertise in regional governance, consultancy, crisis intervention, and compliance, we work tirelessly to support care homeowners, investors, and practitioners in achieving their goals. Our collaborative approach, innovative practices, and unwavering dedication ensure the highest standards of care for residents and exceptional value for our clients.
</p>


    </div>
      </div>
{/* 
      <div className="ourteam-main">
          <div className="ourteam-members">
            <img src="https://tse1.explicit.bing.net/th?id=OIP.GHGGLYe7gDfZUzF_tElxiQHaHa&pid=Api&P=0&h=180" alt="" srcset="" />
            <div className="member-data">
              <span>xxxxxxx</span>
              <span>
Operations Director</span>

              <span>
Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores at voluptatibus aut doloremque iure voluptas ipsum error accus</span>
            </div>
          </div>
          <div className="ourteam-members">
            <img src="https://tse1.explicit.bing.net/th?id=OIP.GHGGLYe7gDfZUzF_tElxiQHaHa&pid=Api&P=0&h=180" alt="" srcset="" />
            <div className="member-data">
              <span>xxxxxxxxx</span>
              <span>
Operations Director</span>

              <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores at voluptatibus aut doloremque iure voluptas ipsum error accus

</span>
            </div>
          </div>
          <div className="ourteam-members">
            <img src="https://tse1.explicit.bing.net/th?id=OIP.GHGGLYe7gDfZUzF_tElxiQHaHa&pid=Api&P=0&h=180" alt="" srcset="" />
            <div className="member-data">
              <span>xxxxxxxxx</span>
              <span>
Operations Director</span>

              <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores at voluptatibus aut doloremque iure voluptas ipsum error accus
</span>
            </div>
          </div>
          <div className="ourteam-members">
            <img src="https://tse1.explicit.bing.net/th?id=OIP.GHGGLYe7gDfZUzF_tElxiQHaHa&pid=Api&P=0&h=180" alt="" srcset="" />
            <div className="member-data">
              <span>xxxxxxxxx</span>
              <span>
Operations Director</span>

              <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores at voluptatibus aut doloremque iure voluptas ipsum error accus
</span>
            </div>
          </div>
          <div className="ourteam-members">
            <img src="https://tse1.explicit.bing.net/th?id=OIP.GHGGLYe7gDfZUzF_tElxiQHaHa&pid=Api&P=0&h=180" alt="" srcset="" />
            <div className="member-data">
              <span>xxxxxxxxx</span>
              <span>
Operations Director</span>

              <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores at voluptatibus aut doloremque iure voluptas ipsum error accus
</span>
            </div>
          </div>
          <div className="ourteam-members">
            <img src="https://tse1.explicit.bing.net/th?id=OIP.GHGGLYe7gDfZUzF_tElxiQHaHa&pid=Api&P=0&h=180" alt="" srcset="" />
            <div className="member-data">
              <span>xxxxxxxx</span>
              <span>
Operations Director</span>

              <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores at voluptatibus aut doloremque iure voluptas ipsum error accus
</span>
            </div>
          </div>
      </div> */}

    </div>
  )
}

export default OurTeam