import React from "react";
import "./Cases1.css";

const Cases1 = () => {
  return (
    <div className="Cases1">
      <div className="case-heading">
        <span>Case 1</span>
        <span>
          Enhancing Staff Competency and Regulatory Compliance at a Residential
          Care Facility
        </span>
      </div>

      <div className="cases-div">
        <div className="case-issue">
          <p>
            <b>Issue :</b> A medium-sized residential care facility, catering to
            elderly residents with diverse care needs, encountered persistent
            challenges in maintaining staff competency and regulatory
            compliance. Despite periodic training sessions and internal audits,
            inconsistencies in care practices, documentation standards, and
            adherence to regulatory guidelines continued to pose risks to
            resident safety and the facility's regulatory standing. Concerns
            were raised regarding infection control practices, medication
            management protocols, safeguarding procedures, and the application
            of person-centered care principles.
          </p>
        </div>
        <div className="case-help">
          <p>
            <b> How Advance Care Helped :</b> Advance Care Solutions conducted a
            comprehensive assessment of the facility's operations, focusing on
            staff competency levels, compliance with regulatory standards, and
            areas requiring improvement. The assessment revealed gaps in staff
            training, inconsistent adherence to care protocols, and deficiencies
            in documentation practices. Based on these findings, a tailored
            intervention plan was developed in collaboration with the facility's
            management team.
          </p>
        </div>
      </div>

      <div className="case-plan">
        <span>The intervention plan comprised several key components:</span>

        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed button-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                1.Customized Training Program :
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Advance Care Solutions
                designed a targeted training program addressing the identified
                gaps in staff competency. The program covered essential topics
                such as infection control practices, medication management
                protocols, safeguarding procedures, and the application of
                person-centered care principles. Training sessions were
                interactive, engaging, and tailored to the specific needs of the
                facility's staff.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button
                class="accordion-button collapsed button-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                2. Workshops and Coaching Sessions :
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                In addition to
                traditional classroom-style training, Advance Care Solutions
                conducted interactive workshops and one-on-one coaching sessions
                with staff members. These sessions provided opportunities for
                hands-on learning, skill development, and individualized
                support. Staff were encouraged to ask questions, share
                experiences, and seek clarification on complex topics.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <button
                class="accordion-button collapsed button-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
               3.  Ongoing Support and Guidance :
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                 Advance Care Solutions
                provided continuous support and guidance to the facility's staff
                throughout the implementation phase. Experienced consultants
                were available to address staff queries, provide additional
                training as needed, and offer practical advice on overcoming
                challenges encountered during the transition period.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
              <button
                class="accordion-button collapsed button-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                 4. Monitoring and Evaluation Framework :
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                To track
                progress and measure the effectiveness of the intervention,
                Advance Care Solutions implemented a robust monitoring and
                evaluation framework. Key performance indicators related to
                staff competency, regulatory compliance, and resident outcomes
                were established. Regular audits, feedback sessions, and
                performance reviews were conducted to assess the impact of the
                intervention and identify areas for further improvement.
              </div>
            </div>
          </div>
        </div>


  {/* vbvnvnnvnv */}
        <span>The collaboration with Advance Care Solutions yielded significant improvements across various areas:</span>    

        <div
          class="accordion"
          id="accordionExample"
          
        >
          <div class="accordion-item boot-button6-main">
            <h2
              class="accordion-header  "    
            >
              <button
                class="accordion-button  boot-button6-main button-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
               1. Enhanced Staff Competency :
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse "
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
               Staff members demonstrated increased confidence, proficiency, and consistency in their roles. Training sessions and coaching interventions facilitated the acquisition of essential skills and knowledge, empowering staff to deliver high-quality care consistently.

              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button button-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
           2.	Improved Regulatory Compliance:
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
              The facility achieved higher levels of compliance with regulatory standards, resulting in fewer findings and deficiencies during external inspections and audits. Staff adherence to infection control protocols, medication management guidelines, and safeguarding procedures improved significantly.

              </div>
            </div>
          </div>
          <div class="accordion-item ">
            <h2 class="accordion-header">
              <button
                class="accordion-button button-txt" 
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                3.	Enhanced Resident Care:
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body accordion-bgclr">
              The improvements in staff competency and regulatory compliance translated into better outcomes for residents. Enhanced care practices, personalized support, and improved communication contributed to an overall improvement in the quality of life and satisfaction levels among residents.

              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button button-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
              4.	Positive Reputation: 
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body accordion-bgclr">
              The facility's commitment to staff development, continuous improvement, and resident-centered care enhanced its reputation within the community and among regulatory authorities. Stakeholders, including residents, families, and regulatory agencies, recognized the facility's efforts to prioritize safety, quality, and compliance.

              </div>
            </div>
          </div>

          
        </div>

      </div>

      <div className="case-overall">
          <p>
          Overall, the partnership with Advance Care Solutions facilitated sustainable improvements in staff competency and regulatory compliance, ensuring the delivery of high-quality care and positive outcomes for residents. The collaborative approach, customized interventions, and ongoing support provided by Advance Care Solutions contributed to the success of the initiative and the long-term success of the residential care facility.
          </p>
        </div>
    </div>
  );
};

export default Cases1;
