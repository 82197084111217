import React, { useEffect } from 'react'
import './Main1.css'
import AOS from 'aos'
const Maincard = () => {
    useEffect(()=>{
    AOS.init({duration:1000})
  })
  return (
    <div className='cases-mian-insight'>

        <div className='cases-single-card-insight' data-aos='fade-up'>
            
                    <img className='cases-single-card-image-insight' src="https://c.stocksy.com/a/jhN400/z9/1044435.jpg" alt="" />

                    <span className='case-sudies-sspam'>Crisis Management Plans | How We Can Help You Handle the Worst Case Scenario</span>
                    <span >At Fulcrum, we’re driven by our passion to empower care providers to achieve excellence in care.  We understand the challenges and demands that care professionals and management teams face daily. So, to further support them, we’re delighted to introduce our innovative...</span>
        </div>

        <div className='cases-single-card-insight' data-aos='fade-up'>
            
            <img className='cases-single-card-image-insight' src="https://www.signaturecopier.com/wp-content/uploads/2020/08/article_1_pic-1e36971952.jpg" alt="" />

            <span className='case-sudies-sspam'>How to slow down the revolving door of recruitment and staff retention in your care home</span>
            <span >How to slow down the revolving door of recruitment and staff retention in your care home It is typical for care providers to struggle with a high turnover of care staff if they rely upon contract and temporary care workers. However, there are several strategies that..</span>
</div>


<div className='cases-single-card-insight' data-aos='fade-up'>
            
            <img className='cases-single-card-image-insight' src="https://data.1freewallpapers.com/download/notebook-hand-pen-paper.jpg" alt="" />

            <span className='case-sudies-sspam'>Fire safety in care homes: do you comply?</span>
            <span> Residential care homes are particularly vulnerable to injury and loss of life in the event of a fire due to a high density of at-risk occupants, including those with mobility issues. According to official national statistics, 498 care homes experienced a primary fire...</span>
</div>




<div className='cases-single-card-insight' data-aos='fade-up'>
            
            <img className='cases-single-card-image-insight' src="https://www.avepoint.com/blog/wp-content/uploads/2018/09/thoughtful-businessman-in-office-working-on-laptop-picture-id909203328.jpg" alt="" />

            <span className='case-sudies-sspam'>Crisis Management Plans | How We Can Help You Handle the Worst Case Scenario</span>
            <span >At Fulcrum, we’re driven by our passion to empower care providers to achieve excellence in care.  We understand the challenges and demands that care professionals and management teams face daily. So, to further support them, we’re delighted to introduce our innovative...</span>
</div>

<div className='cases-single-card-insight' data-aos='fade-up'>
    
    <img className='cases-single-card-image-insight' src="https://tse2.explicit.bing.net/th?id=OIP.iIkQOGui7p8qvunfrbhp5wHaE8&pid=Api&P=0&h=180" alt="" />

    <span className='case-sudies-sspam'>How to slow down the revolving door of recruitment and staff retention in your care home</span>
    <span >How to slow down the revolving door of recruitment and staff retention in your care home It is typical for care providers to struggle with a high turnover of care staff if they rely upon contract and temporary care workers. However, there are several strategies that..</span>
</div>


<div className='cases-single-card-insight' data-aos='fade-up'>
    
    <img className='cases-single-card-image-insight' src="https://courses.thetraining.shop/wp-content/uploads/2020/10/541-1.jpg" alt="" />

    <span className='case-sudies-sspam'>Fire safety in care homes: do you comply?</span>
    <span> Residential care homes are particularly vulnerable to injury and loss of life in the event of a fire due to a high density of at-risk occupants, including those with mobility issues. According to official national statistics, 498 care homes experienced a primary fire...</span>
</div>  









<div className='cases-single-card-insight' data-aos='fade-up'>
            
            <img className='cases-single-card-image-insight' src="https://www.carriermanagement.com/assets/bigstock-File-98887520-businessman-giving-files-sharing-information-connect-disconnect.jpg" alt="" />

            <span className='case-sudies-sspam'>Crisis Management Plans | How We Can Help You Handle the Worst Case Scenario</span>
            <span >At Fulcrum, we’re driven by our passion to empower care providers to achieve excellence in care.  We understand the challenges and demands that care professionals and management teams face daily. So, to further support them, we’re delighted to introduce our innovative...</span>
</div>

<div className='cases-single-card-insight' data-aos='fade-up'>
    
    <img className='cases-single-card-image-insight' src="https://fulcrumcareconsulting.com/wp-content/uploads/2023/04/pexels-tima-miroshnichenko-5439470-400x284.jpg" alt="" />

    <span className='case-sudies-sspam'>How to slow down the revolving door of recruitment and staff retention in your care home</span>
    <span >How to slow down the revolving door of recruitment and staff retention in your care home It is typical for care providers to struggle with a high turnover of care staff if they rely upon contract and temporary care workers. However, there are several strategies that..</span>
</div>


<div className='cases-single-card-insight' data-aos='fade-up'>
    
    <img className='cases-single-card-image-insight' src="https://www.okaloneworker.com/wp-content/uploads/2016/12/home-car-workers-need-time.jpg" alt="" />

    <span className='case-sudies-sspam'>Fire safety in care homes: do you comply?</span>
    <span> Residential care homes are particularly vulnerable to injury and loss of life in the event of a fire due to a high density of at-risk occupants, including those with mobility issues. According to official national statistics, 498 care homes experienced a primary fire...</span>
</div>  
















      
    </div>
  )
}

export default Maincard