import React from 'react'

const Content = () => {
  return (
    <div className='ser-content'>
        

        <p>Healthcare Management Solutions offers a comprehensive suite of services catering to the needs of Care Home Owners, Investors, Banks, and Insolvency Practitioners. Based in our specialized Head Office in South Birmingham, our team comprises experienced Regional Managers, Consultants, and dedicated Support Professionals. Our approach is adaptable and customized to meet the unique demands of each project. We leverage innovative practices, cutting-edge systems, and a range of services to craft tailored solutions. Our ultimate goal is to enhance the quality of care for residents while simultaneously elevating performance and value for our clients.</p>

   <p> As Healthcare Management Solutions embarks on an exciting phase of growth, we're actively seeking fresh talent to join our dynamic team. With upcoming projects in care homes across the United Kingdom, we're on the lookout for exceptional professionals. Whether you're an accomplished Regional Manager, Facilities Manager, or a skilled addition to our Head Office team, we're eager to welcome industry leaders to be part of our expanding journey.</p>

<p>  Scalability: HCMS can offer a management platform capable of adding any number of services at any time, allowing growth to be determined by opportunity and not restricted by capacity. Whatever size the portfolio, HCMS clients benefit from the resources of a large corporate provider with the capacity to smoothly onboard and integrate new home and groups, whatever the size.</p>

  <p>Comprehensive solution: HCMS offers a fully comprehensive solution from the outset. With over a hundred employees providing various functions including Operations, Finance, Quality, IT, HR, Estates, Training, and Compliance, clients have access to a full suite of management functions to support their investment, whether beginning with 1 service or 20.</p>

  <p>Management: The HCMS team has a proven track record of managing care services, mitigating risks associated with recruiting new teams, especially during periods of growth and acquisition where regulatory scrutiny is at its highest.</p>

  <p>Geography: With a national presence, HCMS ensures investors are not geographically restricted when seeking new opportunities, increasing the chances of identifying and securing assets at the right price.</p>

  <p>Cost: HCMS provides a fixed cost management solution, simplifying investment decisions.</p>

  <p>Exit: At exit, options include a company or asset sale, simplifying the process and avoiding the complexity of making an entire head office team redundant. Alternatively, investors can consider continuing to contract with HCMS for management services in the long or short term.</p>
    </div>
  )
}

export default Content