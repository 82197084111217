import React, { useEffect } from 'react'
import './Main1.css'
import Footer from '../components/Footer'
import Caremenu from './Caremenu'
import AOS from 'aos'

const Case = () => {
 
    useEffect(()=>{
     AOS.init({duration:1000})
   })
  return (
    <>
    <div>
        <div className=' case-main-content'>
          <div className="about-opacity"></div>
          <div className='about-texts'>
             <h1>Our Approach to Mock CQC Inspections</h1>
             <p>At Advance Care Consultancy, we understand that each care home is unique, with its own set of challenges and priorities. Therefore, we offer two types of mock CQC inspections to cater to the diverse needs of our clients:</p>
          </div>

    </div>
    </div>
    <div className="Consultancy-data">
        <h1  data-aos='fade-up'>Our Approach to Mock CQC Inspections</h1>

        <p  data-aos='fade-up'>
        Our Approach to Mock CQC Inspections: At Advance Care Consultancy, we understand that each care home is unique, with its own set of challenges and priorities. Therefore, we offer two types of mock CQC inspections to cater to the diverse needs of our clients

        </p>

        <h4  data-aos='fade-up'>1. Remote CQC Mini Mock Inspection:</h4>
        <p  data-aos='fade-up'>Our Remote CQC Mini Mock Inspection is designed for care homes looking to maintain or elevate their existing CQC rating with minimal disruption to their operations. Here's how it works:
</p>

        <h4  data-aos='fade-up'>Immediate Response: </h4>

        <p  data-aos='fade-up'>

        Convenience: Conducted remotely, this inspection option offers convenience and flexibility for care homes. Our consultants schedule a time that works best for your team, minimizing any disruption to your daily activities.

        </p>

        <h4  data-aos='fade-up'>Efficiency: </h4>
        <p  data-aos='fade-up'>
        
 Completed in just a few hours, our consultants conduct a focused assessment of key areas of your care home's operations, including documentation, staff practices, and resident care plans.

        </p>
        <h4  data-aos='fade-up'>Detailed Feedback:</h4>
        <p  data-aos='fade-up'>
        Following the inspection, our consultants provide detailed feedback on areas of strength and areas for improvement. We offer practical recommendations for enhancing compliance with CQC standards and improving overall care quality.

        </p>
        <h4  data-aos='fade-up'>Tailored Support: </h4>
        <p  data-aos='fade-up'>
       
Based on the findings of the inspection, we offer tailored support and guidance to help address any identified areas of concern. Whether it's updating care plans, providing additional staff training, or implementing new protocols, we work collaboratively with your team to drive meaningful improvements.

        </p>

      
        <h4  data-aos='fade-up' style={{ color: "#7469B6" }}>2. Comprehensive In-Person CQC Mock Inspection:</h4>
     <p  data-aos='fade-up'>In-Depth Assessment: Our experienced team of consultants conducts a comprehensive evaluation of your care home, covering all areas outlined in the CQC's inspection framework. This includes reviewing documentation, observing care practices, and interviewing staff and residents.
</p>
        <h4  data-aos='fade-up'>Collaborative Approach:</h4>
        <p  data-aos='fade-up'>
         We work closely with your team throughout the inspection process, fostering collaboration and open communication. Our goal is to ensure that everyone is engaged and informed, empowering your staff to actively participate in the improvement process.

        </p>

        <h4  data-aos='fade-up'>Thorough Feedback:</h4>
    
<p  data-aos='fade-up'>
 Following the inspection, we provide detailed feedback on areas of compliance and areas needing improvement. Our consultants offer actionable recommendations and practical solutions for addressing any identified gaps, helping you enhance care quality and meet regulatory requirements.

        </p>

        <h4  data-aos='fade-up'>Implementation Support: </h4>
        <p  data-aos='fade-up'>
    
We offer ongoing support and guidance to help you implement recommended changes effectively. Whether it's developing new policies and procedures, conducting staff training sessions, or updating documentation, we're here to support you every step of the way.

        </p>

        <p  data-aos='fade-up'>
        By choosing Advance Care Consultancy for your mock CQC inspection needs, you can trust that you're partnering with a team dedicated to helping you achieve and maintain the highest standards of care provision. Contact us today to book your mock inspection and take the first step towards excellence in care delivery.
        </p>
      </div>
  
   
    </>
  )
}

export default Case