import React, { useEffect } from 'react'
import AOS from 'aos'
import { FaGreaterThan } from "react-icons/fa";
const Menu = () => {
  // useEffect(()=>{
  //   AOS.init({duration:1000})
  // })
  return (
     
          <div className='mnb'>
              

              <div className='menu1-main'  data-aos='fade-up'>
                <span className='menu1-span'>Service We Provide</span>
                <span className='menu1-span-1'>Professional Psychology Therapy Services You Can Choose</span>
              </div>
              <div className='menu-carss' data-aos='fade-up'>
                  <div className='menu-cars-1'>
                       <img className='mennu-cars-img1' src="https://www.advance-caresolutions.co.uk/img/img-1.jpg" alt="" />
                     <div className='mennu-cimage'>
                        <img src="https://html.designingmedia.com/medunit/assets/images/type-img2.png" alt="" />
                        </div>
                        <span className='menu-care'>Nursing Care</span>
                        <span className='menu-care1'>Nursing care refers to the specialized care provided by licensed....</span>
                        <span className='menu-read'>Read More <FaGreaterThan style={{color:'#156C5F',fontSize:'10px',marginTop:5,marginLeft:4}} /> </span>
                  </div>
                  <div className='menu-cars-1'>
                  <img className='mennu-cars-img1' src="https://www.advance-caresolutions.co.uk/img/img-2.jpg" alt="" />
                     <div className='mennu-cimage mennu-cimage1'>
                        <img src="https://html.designingmedia.com/medunit/assets/images/type-img3.png" alt="" />
                        </div>
                        <span className='menu-care'>Mental Health Care</span>
                        <span className='menu-care1'>Mental health care involves the assessment, treatment, and support...</span>
                        <span className='menu-read'>Read More <FaGreaterThan style={{color:'#156C5F',fontSize:'10px',marginTop:5,marginLeft:4}} /> </span>
                  </div>
                  <div className='menu-cars-1'>
                            <img className='mennu-cars-img1' src="https://www.advance-caresolutions.co.uk/img/img-3.jpg" alt="" />
                     <div className='mennu-cimage mennu-cimage2'>
                        <img src="https://html.designingmedia.com/medunit/assets/images/type-img4.png" alt="" />
                        </div>
                        <span className='menu-care'>Dementia Care</span>
                        <span className='menu-care1'>Dementia care involves specialized support and services for indivi...</span>
                        <span className='menu-read'>Read More <FaGreaterThan style={{color:'#156C5F',fontSize:'10px',marginTop:5,marginLeft:4}} /> </span>
                  </div>
              </div>
                
         </div>
    
  

  )
}

export default Menu