import React from 'react'
import './Main1.css'
import Footer from '../components/Footer'
import Caremenu from './Caremenu'

const League = () => {
  return (
    <>
    <div>
        <div className='care-main-cqc league-main-content'>
          <div className="about-opacity"></div>
          <div className='about-texts'>
             <h1 >FAQs about Mock CQC Inspections </h1>
             {/* <p>The following, interactive tables based on CQC data shows you the ratings by Local Authority area or CCG (Clinical Commissioning Group) area.</p> */}
          </div>




    </div>
    <div
                class="accordion accordion-flush"
                style={{ color: "black",width:"80%",margin:"70px auto" }}
                id="accordionFlushExample"
              >
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                     1. What is a mock CQC inspection?

                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                    A mock CQC inspection is a practice exercise conducted by Advance Care Solutions to assess a healthcare facility's compliance with Care Quality Commission (CQC) standards. It helps identify areas for improvement and prepares the facility for a real inspection by providing feedback and recommendations.
 </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                    2. How does a mock CQC inspection work?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                    During a mock CQC inspection, our trained inspectors evaluate healthcare provider services to assess compliance with CQC standards. This includes reviewing documentation, conducting interviews, and observing care delivery. The goal is to identify areas of non-compliance or potential risks and improve services before the actual inspection.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                        3. How often are CQC inspections?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                 
The frequency of CQC inspections varies depending on factors such as the type of healthcare or social care service being provided and the CQC's assessment of risk.
  </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFour">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                     4. What is the new CQC inspection framework from 2023?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                   The new inspection framework introduced in 2023 by the CQC improves transparency by offering detailed assessments of healthcare facilities' performance. It evaluates all service types and levels to determine quality, providing important information to patients and their families.

                    </div>
                  </div>
                </div>


                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFive">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
 5. How much does a mock CQC inspection cost?                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                   
A mock CQC inspection from Advance Care Solutions costs £499.00 plus VAT.

                    </div>
                  </div>
                </div>




                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingSix">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                     6. What are the 3 types of CQC inspections?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                   
	The three types of CQC inspections are comprehensive inspections, focused inspections, and responsive inspections. Each type targets specific areas or concerns identified by the CQC.

                    </div>
                  </div>
                </div>


                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingSeven">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSeven"
                      aria-expanded="false"
                      aria-controls="flush-collapseSeven"
                    >
  7. Do you provide mock CQC inspections for dental practices?                    </button>
                  </h2>
                  <div
                    id="flush-collapseSeven"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingSeven"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
	Yes, we provide mock CQC inspections for all areas of health and social care, including dentistry, to ensure that all care and treatment meet CQC standards.
                    </div>
                  </div>
                </div>
                


                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingEight">
                    <button
                      class="accordion-button collapsed button-txt"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEight"
                      aria-expanded="false"
                      aria-controls="flush-collapseEight"
                    >
  8. What are the 5 CQC questions?                    </button>
                  </h2>
                  <div
                    id="flush-collapseEight"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingEight"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                  
•	The CQC asks the same five questions to all care services they inspect: <br />
•	Are they safe? <br />
•	Are they effective? <br />
•	Are they caring? <br />
•	Are they responsive to people’s needs? <br />
•	Are they well-led? Each question is further explored through a set of key lines of enquiry.

                    </div>
                  </div>
                </div>


              </div>


    </div>
    </>
  )
}

export default League