import React, { useEffect } from 'react'
import './DementiaSupport.css'
import '../../AboutPic/AboutPic.css'
import AOS from 'aos'

const DementiaSupport = () => {

    useEffect(()=>{
     AOS.init({duration:1000})
   })
  return (
    <div className='Consultancy'>
    <div className="DementiaSupport-pic">
  {/* <img src={apic} alt="" srcset="" /> */}
  <div className="about-opacity"></div>
  <div className="about-texts">
<h1>Dementia-Behavioural and Psychological Management Support Services</h1>
<p>
Managing complex dementia care needs requires specialized expertise. Our advanced dementia care practitioners offer tailored support to address behavioural and psychological symptoms, ensuring residents receive holistic, person-centred care.
</p>


</div>
</div>

<div className="Consultancy-data">
  <h1  data-aos='fade-up'>Dementia-Behavioural and Psychological Management Support Services</h1>

  <p  data-aos='fade-up'>
  Advance Care Solutions offers Dementia-Behavioural and Psychological Management Support Services to nursing and residential care homes, providing expert guidance and assistance in managing complex dementia-related behaviours and symptoms.

</p>


<h4  data-aos='fade-up'>Our services include:

</h4>


<h4  data-aos='fade-up'>1.	Comprehensive Assessment:</h4>

<p  data-aos='fade-up'>
We conduct thorough assessments of individuals with dementia to identify specific behavioural and psychological symptoms and their underlying causes.
</p>


<h4  data-aos='fade-up'>2.	Tailored Care Plans: </h4>
<p  data-aos='fade-up'> 
Based on the assessment findings, we develop personalized behaviour management care plans tailored to individual needs and preferences.
</p>
<h4  data-aos='fade-up'>3.	Staff Training:</h4>
<p  data-aos='fade-up'>
We provide training and education to care home staff on evidence-based strategies for managing behavioural and psychological symptoms of dementia (BPSD). This includes techniques for de-escalating agitation, addressing anxiety, and promoting a supportive environment.
</p>
<h4  data-aos='fade-up'>4.	Behavioral Interventions:  </h4>
<p  data-aos='fade-up'>
We offer guidance and support in implementing non-pharmacological interventions to address challenging behaviours associated with dementia, such as agitation, aggression, and hallucinations.
</p>

<h4  data-aos='fade-up'>5.	Ongoing Support and Monitoring:</h4>
<p  data-aos='fade-up'>
Our team provides ongoing support and monitoring to ensure the effectiveness of implemented interventions and make adjustments as needed to optimize resident care and well-being.
</p>

<h4  data-aos='fade-up'>6.	Family and Caregiver Education: </h4>
<p  data-aos='fade-up'>
We offer education and support to family members and caregivers, equipping them with the knowledge and skills to effectively manage behavioural and psychological symptoms of dementia and improve their loved one's quality of life.
</p>

<h4  data-aos='fade-up'>7.	Collaboration with Healthcare Professionals:</h4>
<p  data-aos='fade-up'>
We collaborate closely with healthcare professionals, including physicians, psychologists, and occupational therapists, to provide holistic and integrated care for individuals with dementia.
</p>
<p  data-aos='fade-up'>
By partnering with Advance Care Solutions for Dementia-Behavioural and Psychological Management Support Services, care homes can enhance their capacity to provide person-centred care and support individuals living with dementia in achieving the highest possible quality of life.
</p>
</div>

</div>
  )
}

export default DementiaSupport