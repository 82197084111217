import React, { useEffect } from 'react'
import './CrisisManagement .css'
import '../../AboutPic/AboutPic.css'
import AOS from 'aos'


const CrisisManagement  = () => {

    useEffect(()=>{
     AOS.init({duration:1000})
   })
  return (
    <div className='Consultancy'>
    <div className=" consult-bgl-pic">
  {/* <img src={apic} alt="" srcset="" /> */}
  <div className="about-opacity"></div>
  <div className="about-texts">
<h1>Consultancy and Support</h1>
<p>Consultancy and support services are crucial in helping care homes navigate the complexities of healthcare management and regulatory compliance. Advance Care Solutions offers tailored consultancy and support solutions designed to address the unique needs and challenges care homeowners and operators face.

</p>


</div>
</div>

<div className="Consultancy-data">
  <h1  data-aos='fade-up'>Consultancy and Support: </h1>

  <p  data-aos='fade-up'>Consultancy and support services play a crucial role in helping care homes navigate the complexities of healthcare management and regulatory compliance. At Advance Care Solutions, we offer tailored consultancy and support solutions designed to address the unique needs and challenges faced by care home owners and operators.</p>


<h4  data-aos='fade-up'>Our consultancy services encompass a wide range of areas, including but not limited to:</h4>


<h4  data-aos='fade-up'>1. Regulatory Compliance:</h4>

<p  data-aos='fade-up'>We provide expert guidance on meeting the standards set by regulatory bodies such as the Care Quality Commission (CQC). Our consultants offer comprehensive assessments, audits, and recommendations to ensure care homes remain compliant with regulatory requirements.
</p>


<h4  data-aos='fade-up'>2. Operational Efficiency: </h4>
<p  data-aos='fade-up'>We work closely with care home management teams to identify areas for improvement and implement strategies to enhance operational efficiency. This may include streamlining processes, optimizing resource allocation, and implementing best practices to improve overall performance.
</p>
<h4  data-aos='fade-up'>3. Crisis Management</h4>
<p  data-aos='fade-up'>In the event of a crisis or emergency situation, our experienced consultants are on hand to provide immediate support and guidance. From managing staffing shortages to addressing critical issues impacting resident care, we offer practical solutions to mitigate risks and ensure continuity of care.
</p>
<h4  data-aos='fade-up'>4. Quality Improvement</h4>
<p  data-aos='fade-up'>
We assist care homes in implementing quality improvement initiatives to enhance the overall quality of care provided to residents. This may involve developing and implementing care plans, training staff on best practices, and monitoring outcomes to drive continuous improvement.
</p>

<h4  data-aos='fade-up'>5. Staff Training and Development</h4>
<p  data-aos='fade-up'>
We offer training and development programs designed to enhance the skills and competencies of care home staff. From leadership training for managers to specialized training in areas such as dementia care and infection control, our programs are tailored to meet the specific needs of care home teams.
</p>


<h4  data-aos='fade-up'>6. Financial Management:</h4>
<p  data-aos='fade-up'>
 Our consultancy services extend to financial management, helping care homes optimize their financial performance and maximize profitability. We offer guidance on budgeting, cost management, revenue optimization, and financial planning to ensure long-term sustainability.
</p>


<p  data-aos='fade-up'>
Through our consultancy and support services, Advance Care Solutions empowers care homes to overcome challenges, improve outcomes, and achieve excellence in care delivery. With a team of experienced consultants and a commitment to personalized service, we are dedicated to helping care homes thrive in an ever-evolving healthcare landscape.
</p>
</div>


</div>
  )
}

export default CrisisManagement 